import { Component } from '@angular/core';

@Component({
  selector: 'app-current-date',
  templateUrl: './current-date.component.html',
  styleUrls: ['./current-date.component.scss']
})
export class CurrentDateComponent {

  public date = new Date();

  constructor() { }

  getHour() {
    const hours = this.date.getHours();

    if (hours < 10) {
      return '0' + hours;
    }

    return hours;
  }

  getMinute() {
    const minutes = this.date.getMinutes();

    if (minutes < 10) {
      return '0' + minutes;
    }

    return minutes;
  }

}
