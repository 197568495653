import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-signal-strength',
  templateUrl: './signal-strength.component.html',
  styleUrls: ['./signal-strength.component.scss']
})
export class SignalStrengthComponent implements OnInit {

  @Input() signal: number;
  @Input() isMeasurement: boolean;
  public strength: number;

  constructor() { }

  ngOnInit() {
    this.strength = this.getSignalLevel();

    if (this.strength > 0) {
      this.isMeasurement = true;
    }

    if (this.signal === 200) {
      this.isMeasurement = false;
    }

  }

  public getSignalLevel() {

    this.signal = Math.abs(this.signal);

    if (this.signal > 119 && this.signal <= 105) {
      return 0;
    } else if (this.signal < 105 && this.signal >= 92) {
      return 1;
    } else if (this.signal < 92 && this.signal >= 78) {
      return 2;
    } else if (this.signal < 78 && this.signal >= 64) {
      return 3;
    } else if (this.signal <= 50) {
      return 4;
    }

  }

}
