import { Directive, ElementRef, Renderer2, HostListener, OnInit, Input } from '@angular/core';

@Directive({
    selector: '[appDraggable]'
})
export class DraggableDirective implements OnInit {
    @Input() minWidth = 555; // Minimalna szerokość w pikselach
    @Input() minHeight = 834; // Minimalna wysokość w pikselach

    private dragging = false;
    private resizing = false;
    private initialX: number;
    private initialY: number;
    private initialWidth: number;
    private initialHeight: number;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'move');
        this.addResizeHandle();
    }

    private addResizeHandle() {
        const resizeHandle = this.renderer.createElement('div');
        this.renderer.setStyle(resizeHandle, 'width', '20px');
        this.renderer.setStyle(resizeHandle, 'height', '20px');
        this.renderer.setStyle(resizeHandle, 'background', 'transparent');
        this.renderer.setStyle(resizeHandle, 'cursor', 'se-resize');
        this.renderer.setStyle(resizeHandle, 'position', 'absolute');
        this.renderer.setStyle(resizeHandle, 'right', '0');
        this.renderer.setStyle(resizeHandle, 'bottom', '0');
        this.renderer.setStyle(resizeHandle, 'border-right', '2px solid #ccc');
        this.renderer.setStyle(resizeHandle, 'border-bottom', '2px solid #ccc');
        this.renderer.appendChild(this.el.nativeElement, resizeHandle);


        this.renderer.listen(resizeHandle, 'mousedown', (event: MouseEvent) => {
            this.resizing = true;
            this.initialWidth = this.el.nativeElement.offsetWidth;
            this.initialHeight = this.el.nativeElement.offsetHeight;
            this.initialX = event.clientX;
            this.initialY = event.clientY;
            event.preventDefault();
        });
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        if (!this.resizing) {
            event.preventDefault();
            this.dragging = true;
            this.initialX = event.clientX - this.el.nativeElement.offsetLeft;
            this.initialY = event.clientY - this.el.nativeElement.offsetTop;
        }
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (this.dragging) {
            event.preventDefault();
            const newX = event.clientX - this.initialX;
            const newY = event.clientY - this.initialY;
            this.renderer.setStyle(this.el.nativeElement, 'left', `${newX}px`);
            this.renderer.setStyle(this.el.nativeElement, 'top', `${newY}px`);
        } else if (this.resizing) {
            event.preventDefault();
            let newWidth = this.initialWidth + (event.clientX - this.initialX);
            let newHeight = this.initialHeight + (event.clientY - this.initialY);

            // Zastosowanie ograniczeń rozmiaru
            newWidth = Math.max(newWidth, this.minWidth);
            newHeight = Math.max(newHeight, this.minHeight);

            this.renderer.setStyle(this.el.nativeElement, 'width', `${newWidth}px`);
            this.renderer.setStyle(this.el.nativeElement, 'height', `${newHeight}px`);
        }
    }

    @HostListener('document:mouseup')
    onMouseUp() {
        this.dragging = false;
        this.resizing = false;
    }
}