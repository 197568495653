import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable()
export class NotificationService {

    users = new Array();
    private currentUsersSubject = new BehaviorSubject<any[]>([]);
    public currentUsers = this.currentUsersSubject.asObservable();
    private audio = new Audio('assets/alarm.mp3');


    constructor(
        private apiService: ApiService
    ) {
        this.audio.load();
    }

    addUserAlarm(user) {
        if (this.audio.paused) {
            this.audio.play();
            this.audio.loop = true;
        }

        if (this.users.findIndex(j => j.alarmID === user.alarmID) === -1) {
            this.users.push(user);
            this.currentUsersSubject.next(this.users);
        }
    }

    addUserEBSAlarm(user) {
        if (this.audio.paused) {
            this.audio.play();
            this.audio.loop = true;
        }

        if (this.users.findIndex(j => j.alarmID === user.alarmID) === -1) {
            this.users.push(user);
            this.currentUsersSubject.next(this.users);
        }
    }

    deleteUserAlarm(user) {
        this.stopAlarm();
        const userIndex = this.users.findIndex(j => j.alarmID == user.alarmID);
        if (userIndex >= 0) {
            this.users.splice(userIndex, 1);
            this.currentUsersSubject.next(this.users);
        }

        // if (this.users.length === 0) {
        //    this.audio.pause();
        // }
    }

    clean() {
        this.users = [];
        this.audio.pause();
        this.currentUsersSubject.next(this.users);
    }

    stopAlarm() {
        this.audio.pause();
    }

    getAlarmUsers(): Observable<any> {
        return this.currentUsers;
    }

    getAlarmArray() {
        return this.users;
    }

    muteAlarm(result) {
        this.audio.muted = result;
    }

    getListOfResponders(imei): Observable<any> {
        return this.apiService.get(`/alarm/getListOfResponders/${imei}`);
    }

    getListOfRespondersBasic(imei): Observable<any> {
        return this.apiService.get(`/alarm/getListOfRespondersBasic/${imei}`);
    }

    sendNotificationsToResponders(data): Observable<any> {
        return this.apiService.post(`/alarm/sendNotif`, data);
    }

    checkAllowNotifyResponders(alarm_id, body): Observable<any> {
        return this.apiService.post(`/alarm/checkAllowNotifyResponders/${alarm_id}`, body);
    }

    getStatusesFromAlarm(alarm_id): Observable<any> {
        return this.apiService.get(`/alarm/getStatusesFromAlarm/${alarm_id}`);
    }

    sentActionsCheck(alarm_id): Observable<any> {
        return this.apiService.get(`/alarm/sentActionsCheck/${alarm_id}`);
    }

}
