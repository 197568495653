import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-show-avatar',
  templateUrl: './show-avatar.component.html',
  styleUrls: ['./show-avatar.component.scss']
})
export class ShowAvatarComponent implements OnInit {
  @Input() link: string;
  @Input() size: string;
  @Input() role: string;
  @Input() imei: string;
  @Input() resizePlanner = false;
  @Input() colour: string;
  @Input() border = false;

  isPlannerImei: boolean = false;

  get avatar() {
    return `${environment.avatar_url}${this.link}`;
  }

  ngOnInit() {
    this.isPlannerImei = this.checkImeiForPlanner();
  }
  checkImeiForPlanner(): boolean {
    return this.imei && this.imei.startsWith('644');
  }
}