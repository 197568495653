import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocketService } from '../../services/socket.service';
import { Subscription } from 'rxjs/Subscription';
import { NgZone } from '@angular/core';
import {take} from 'rxjs/operators';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-sos-alert',
  templateUrl: './sos-alert.component.html',
  styleUrls: ['./sos-alert.component.scss']
})
export class SosAlertComponent implements OnInit, OnDestroy {

  @Input() user;
  private subscription: Subscription;
  public alarm;

  constructor(
    public activeModal: NgbActiveModal,
    private socketService: SocketService,
    private notificationService: NotificationService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {

      this.takeDataFromAlarm();

      this.subscription = this.socketService.onAccept()
        .subscribe(data => {
          this.ngZone.run(() => {

            if (data.id == this.user.id) {
              this.activeModal.dismiss();

            }
              setTimeout(() =>
                  {
                      this.socketService.checkAlarms();
                  },
                  1500);

          });
        });

    });
  }

    takeDataFromAlarm(){
        this.notificationService.getAlarmUsers()
            .pipe(take(1))
            .subscribe(data => {
                if (data.length > 0) {
                    const index = data.findIndex(j => j.alarmID == this.user.alarmID);
                    this.alarm = data[index];
                }
            });
    }



  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
