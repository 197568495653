import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pop-up-html',
  templateUrl: './pop-up-html.component.html',
  styleUrls: ['./pop-up-html.component.scss']
})
export class PopUpHtmlComponent  {

  @Input() message;
  @Input() modalType;

  constructor(public activeModal: NgbActiveModal) { }

}
