import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CaregiverService } from '../../services';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-show-avatar',
  templateUrl: './show-avatar.component.html',
  styleUrls: ['./show-avatar.component.scss']
})
export class ShowAvatarComponent implements OnInit {
  @Input() link: string;
  @Input() size: string;
  @Input() role: string;
  @Input() imei: string;
  @Input() resizePlanner = false;
  @Input() colour: string;
  @Input() border = false;

  isPlannerImei: boolean = false;
  isVitalBand: boolean = null;

  get avatar() {
    return `${environment.avatar_url}${this.link}`;
  }

  constructor(
    private caregiverService: CaregiverService
  ) { }

  ngOnInit() {
    if (this.imei) {
      forkJoin({
        isVital: this.checkImeiForVital(),
        isPlanner: this.checkImeiForPlanner()
      }).subscribe(({ isVital, isPlanner }) => {
        this.isVitalBand = isVital;
        this.isPlannerImei = isPlanner;
      });
    }
  }

  checkImeiForPlanner(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      observer.next(this.imei.startsWith('644'));
      observer.complete();
    });
  }

  checkImeiForVital(): Observable<boolean> {
    return this.caregiverService.getBandType(this.imei).pipe(
      map(data => data === 'REVJ')
    );
  }
}
