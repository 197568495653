import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-diagram-round',
  templateUrl: './diagram-round.component.html',
  styleUrls: ['./diagram-round.component.scss']
})

export class DiagramRoundComponent implements OnInit, OnChanges {

  @Input() data;
  @Input() target;
  @ViewChild('canvas') canvas;
  public chart;
  public percentValue: number;
  public total: number;
  public totalName: string;
  private maxTotal: number;

  constructor() { }

  ngOnInit() {

    this.total = this.countPercentage(this.data, this.target);
    this.maxTotal = this.countMaxTotalPercentage(this.total);

    if(this.total < 1000000)
      this.totalName = this.total.toString();
    else if(this.total > 1000000) {
      this.total = Math.floor(this.total / 1000000);
      this.totalName = this.total + 'mln ';
    }


    this.chart = new Chart(this.canvas.nativeElement, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [this.maxTotal, 100 - this.maxTotal],
          backgroundColor: [
            '#176193',
            '#cfcfcf',
          ],
          hoverBackgroundColor: [
            '#176193',
            '#cfcfcf',
          ],
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        cutoutPercentage: 90
      }
    });
  }

  private countPercentage(data, target): number {
    if (target > 0) {
      return Math.ceil((data / target) * 100);
    } else {
      return 0;
    }
  }

  private countMaxTotalPercentage(total): number {
    if (total > 100) {
      return 100;
    } else {
      return total;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.target.firstChange) {
      this.total = this.countPercentage(this.data, this.target);
      this.maxTotal = this.countMaxTotalPercentage(this.total);
      this.chart.data.datasets[0].data = [this.maxTotal, 100 - this.maxTotal];

      if(this.total < 1000000)
        this.totalName = this.total.toString();
      else if(this.total > 1000000) {
        this.total =  Math.floor(this.total / 1000000);
        this.totalName = this.total + 'mln ';
      }

      this.chart.update();
    }
  }

}
