import { Component, Input, OnInit, NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';

declare var google: any;

@Component({
  selector: 'app-show-map-info',
  templateUrl: './show-map-info.component.html',
  styleUrls: ['./show-map-info.component.scss']
})
export class ShowMapInfoComponent implements OnInit {

  @Input() data;
  @Input() beacon;
  @Input() accuracy;
  public latitude: number;
  public longitude: number;
  public address: string;

  constructor(
    public activeModal: NgbActiveModal,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.latitude = parseFloat(this.data.latitude);
    this.longitude = parseFloat(this.data.longitude);

    if (this.latitude !== null && this.longitude !== null) {
      this.mapsAPILoader.load().then(() => {

        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({
          'location': {
            lat: this.latitude,
            lng: this.longitude
          }
        }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            this.ngZone.run(() => {
              this.address = results[0].formatted_address;
            });
          }
        });

      }).catch(() => {});
    }
  }

  close() {
    this.activeModal.close();
  }
}
