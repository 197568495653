import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FieldErrorsComponent } from './field-errors.component';
import { ShowAuthedDirective } from './show-authed.directive';
import { EnableControlDirective } from './directives/enable-control.directive';
import { PopUpComponent } from './components/pop-up/pop-up.component';

import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ChangeAvatarComponent } from './components/change-avatar/change-avatar.component';
import { SearchComponent } from './components/search/search.component';
import { SliderComponent } from './components/slider/slider.component';
import { HoursBarComponent } from './components/hours-bar/hours-bar.component';
import { AlarmZoneComponent } from './components/alarm-zone/alarm-zone.component';
import { RouteRefreshComponent } from './route-refresh.component';
import { AgmCoreModule } from '@agm/core';
import { CurrentDateComponent } from './components/current-date/current-date.component';
import { ValidateErrorsComponent } from './components/validate-errors/validate-errors.component';
import { SignalStrengthComponent } from './components/signal-strength/signal-strength.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { RangeDatePickerComponent } from './components/range-date-picker/range-date-picker.component';
import { SosAlertComponent } from './components/sos-alert/sos-alert.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { CheckStatusComponent } from './components/check-status/check-status.component';
import { ShowAvatarComponent } from './components/show-avatar/show-avatar.component';
import { ShowAlertInfoComponent } from './components/show-alert-info/show-alert-info.component';
import { ShowAlertInfoIntegrateComponent } from './components/show-alert-info-integrate/show-alert-info-integrate.component';
import { LoadingModule } from 'ngx-loading';
import { ShowMapInfoComponent } from './components/show-map-info/show-map-info.component';
import { DiagramComponent } from './components/diagram/diagram.component';
import { DiagramRoundComponent } from './components/diagram-round/diagram-round.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PhonePickerComponent } from './components/phone-picker/phone-picker.component';
import { BeaconZoneComponent } from './components/beacon-zone/beacon-zone.component';
import { SpecialFormComponent } from './components/special-form/special-form.component';
import { NgxMaskModule } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormModalComponent } from './components/form-modal/form-modal.component';
import { FormQuestionnaireComponent } from './components/form-questionnaire/form-questionnaire.component';
import { CalendarHoursComponent } from './components/calendar-hours/calendar-hours.component';
import { DraggableDirective } from './directives/draggable.directive';


import {
  MatInputModule,
  MatButtonModule,
  MatFormFieldModule,
  MatChipsModule,
  MatDatepickerModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatExpansionModule,
  MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatCheckboxModule
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EbsAlertComponent } from './components/ebs-alert/ebs-alert.component';
import { ShowEbsalertInfoComponent } from './components/show-ebsalert-info/show-ebsalert-info.component';
import { DownloadFileDocumentComponent } from './components/download-file-document/download-file-document.component';
import { UploadFileDocumentComponent } from './components/upload-file-document/upload-file-document.component';
import { SidenavComponent } from './layout/sidenav.component';
import { PopUpHtmlComponent } from './components/pop-up-html/pop-up-html.component';
import { NewHeaderComponent } from './layout/new-header/new-header.component';
import { ValidateErrorsDynamicListComponent } from './components/validate-errors-dynamic-list/validate-errors-dynamic-list.component';
import { StarsComponent } from './components/stars/stars.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DatePickerPlannerComponent } from './components/date-picker-planner/date-picker-planner.component';
import { LeftPanelComponent } from './components/left-panel/left-panel.component';
import { RightPanelComponent } from './components/right-panel/right-panel.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    LoadingModule,
    AgmCoreModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatChipsModule,
    MatDatepickerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCheckboxModule,
    TextMaskModule,
    NgxMaskModule
  ],
  declarations: [
    FieldErrorsComponent,
    ShowAuthedDirective,
    EnableControlDirective,
    PopUpComponent,
    DatePickerComponent,
    DatePickerPlannerComponent,
    DateRangePickerComponent,
    ChangeAvatarComponent,
    SearchComponent,
    HoursBarComponent,
    SliderComponent,
    AlarmZoneComponent,
    BeaconZoneComponent,
    RouteRefreshComponent,
    CurrentDateComponent,
    SignalStrengthComponent,
    ValidateErrorsComponent,
    ValidateErrorsDynamicListComponent,
    StarsComponent,
    FilterButtonComponent,
    RangeDatePickerComponent,
    SosAlertComponent,
    NotificationsComponent,
    CheckStatusComponent,
    ShowAvatarComponent,
    ShowAlertInfoComponent,
    ShowAlertInfoIntegrateComponent,
    ShowMapInfoComponent,
    DiagramComponent,
    DiagramRoundComponent,
    PaginationComponent,
    PhonePickerComponent,
    LeftPanelComponent,
    RightPanelComponent,
    SpecialFormComponent,
    FormModalComponent,
    FormQuestionnaireComponent,
    EbsAlertComponent,
    ShowEbsalertInfoComponent,
    CalendarHoursComponent,
    DownloadFileDocumentComponent,
    UploadFileDocumentComponent,
    SidenavComponent,
    PopUpHtmlComponent,
    NewHeaderComponent,
    LeftPanelComponent,
    DraggableDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FieldErrorsComponent,
    RouterModule,
    ShowAuthedDirective,
    EnableControlDirective,
    TranslateModule,
    PopUpComponent,
    NgbModule,
    DatePickerComponent,
    DatePickerPlannerComponent,
    DateRangePickerComponent,
    ChangeAvatarComponent,
    SearchComponent,
    SliderComponent,
    HoursBarComponent,
    AlarmZoneComponent,
    CurrentDateComponent,
    SignalStrengthComponent,
    ValidateErrorsComponent,
    ValidateErrorsDynamicListComponent,
    StarsComponent,
    FilterButtonComponent,
    RangeDatePickerComponent,
    SosAlertComponent,
    EbsAlertComponent,
    NotificationsComponent,
    CheckStatusComponent,
    ShowAvatarComponent,
    ShowAlertInfoComponent,
    ShowAlertInfoIntegrateComponent,
    ShowEbsalertInfoComponent,
    LoadingModule,
    AgmCoreModule,
    DiagramComponent,
    DiagramRoundComponent,
    PaginationComponent,
    PhonePickerComponent,
    BeaconZoneComponent,
    SpecialFormComponent,
    MatInputModule,
    MatIconModule,
    MatExpansionModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    LeftPanelComponent,
    RightPanelComponent,
    MatSliderModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatStepperModule,
    NgxMaskModule,
    TextMaskModule,
    FormModalComponent,
    FormQuestionnaireComponent,
    MatProgressSpinnerModule,
    CalendarHoursComponent,
    DownloadFileDocumentComponent,
    UploadFileDocumentComponent,
    SidenavComponent,
    NewHeaderComponent,
    DraggableDirective,
  ]
})
export class SharedModule { }
