import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpComponent } from '../components/pop-up/pop-up.component';
import { SosAlertComponent } from '../components/sos-alert/sos-alert.component';
import { EbsAlertComponent } from '../components/ebs-alert/ebs-alert.component';
import { ShowAlertInfoComponent } from '../components/show-alert-info/show-alert-info.component';
import { ShowAlertInfoIntegrateComponent } from '../components/show-alert-info-integrate/show-alert-info-integrate.component';
import { ShowEbsalertInfoComponent } from '../components/show-ebsalert-info/show-ebsalert-info.component';
import { ShowMapInfoComponent } from '../components/show-map-info/show-map-info.component';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { FormModalComponent } from '../components/form-modal/form-modal.component';
import { FormQuestionnaireComponent } from '../components/form-questionnaire/form-questionnaire.component';
import { CaregiverService } from './caregiver.service';
import { PopUpHtmlComponent } from '../components/pop-up-html/pop-up-html.component';
import { tr } from 'date-fns/locale';

@Injectable()
export class ModalService {

    private isActiveAlert: boolean;
    public turnOnAlerts = true;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private translate: TranslateService,
        private caregiverService: CaregiverService
    ) { }

    open(message: string, route?: string) {
        setTimeout(() => {
            const modalRef = this.modalService.open(PopUpComponent, { centered: true, backdrop: 'static', keyboard: false });
            modalRef.componentInstance.modalType = 1;
            modalRef.componentInstance.message = message;

            if (route) {
                modalRef.result.then((result) => {
                    this.router.navigateByUrl(route);
                });
            }
        });
    }


    openOk(message: string): Observable<any> {
        const response: Subject<any> = new Subject<any>();

        setTimeout(() => {

            const modalRef = this.modalService.open(PopUpComponent, { centered: true, backdrop: 'static', keyboard: false });
            modalRef.componentInstance.modalType = 1;
            modalRef.componentInstance.message = message;

            modalRef.result.then(
                (result) => response.next({ ok: true })
            ).catch(
                (reject) => response.next({ ok: false })
            );

        });
        return response;
    }


    openMailSend(message: string, route?: string) {
        setTimeout(() => {
            const modalRef = this.modalService.open(FormModalComponent, { centered: true, backdrop: 'static', keyboard: true });
            modalRef.componentInstance.modalType = 1;
            modalRef.componentInstance.message = message;
            modalRef.componentInstance.mail = message;

            if (route) {
                modalRef.result.then((result) => {
                    this.router.navigateByUrl(route);
                });
            }
        });
    }

    openQuestionnareSend(message: string, route?: string) {
        setTimeout(() => {
            const modalRef = this.modalService.open(FormQuestionnaireComponent, { centered: true, backdrop: 'static', keyboard: true });
            modalRef.componentInstance.modalType = 1;
            modalRef.componentInstance.message = message;

            if (route) {
                modalRef.result.then((result) => {
                    this.router.navigateByUrl(route);
                });
            }
        });
    }

    openAccept(message: string): Observable<any> {
        const response: Subject<any> = new Subject<any>();

        setTimeout(() => {

            const modalRef = this.modalService.open(PopUpComponent, { centered: true, backdrop: 'static', keyboard: false });
            modalRef.componentInstance.modalType = 2;
            modalRef.componentInstance.message = message;

            modalRef.result.then(
                (result) => response.next({ ok: true })
            ).catch(
                (reject) => response.next({ ok: false })
            );

        });
        return response;
    }

    openSosAlert(user) {

        if (this.turnOnAlerts) {
            if (!this.isActiveAlert) {
                this.isActiveAlert = true;
                setTimeout(() => {
                    const modalRef = this.modalService.open(SosAlertComponent, { centered: true, backdrop: 'static', keyboard: false });
                    modalRef.componentInstance.user = user;

                    modalRef.result.then((result) => {
                        this.openAccept(
                            this.translate.instant('BACKEND.ACCEPT-REQUEST-' + user.discr, {
                                lastname: user.last_name,
                                firstname: user.first_name
                            })
                        )
                            .pipe(take(1))
                            .subscribe(data => {
                                if (data.ok === true) {
                                    this.isActiveAlert = false;
                                    this.router.navigateByUrl('/users/sos-request/' + user.alarmID);

                                }
                            });
                    }, (reject) => {
                        this.isActiveAlert = false;
                    });

                });
            }
        }
    }

    openSensorAlert(user) {

        if (this.turnOnAlerts) {
            if (!this.isActiveAlert) {
                this.isActiveAlert = true;
                setTimeout(() => {
                    const modalRef = this.modalService.open(EbsAlertComponent, { centered: true, backdrop: 'static', keyboard: false });
                    modalRef.componentInstance.user = user;

                    modalRef.result.then((result) => {
                        this.openAccept(
                            this.translate.instant('EBS.HANDLE-EBSALARM', {})
                            //this.translate.instant('BACKEND.ACCEPT-REQUEST-' + 'Alarm dymny', {
                            //    lastname: user.last_name,
                            //    firstname: user.first_name
                            //})
                        )
                            .pipe(take(1))
                            .subscribe(data => {
                                if (data.ok === true) {
                                    this.isActiveAlert = false;
                                    this.router.navigateByUrl('/users/ebs-request/' + user.alarmID);

                                }
                            });
                    }, (reject) => {
                        this.isActiveAlert = false;
                    });

                });
            }
        }
    }

    turnOffAlert(status) {
        this.turnOnAlerts = status;
    }

    openAlertInfo(data, bandType) {
        setTimeout(() => {
            const modalRef = this.modalService.open(ShowAlertInfoComponent, { backdrop: 'static', keyboard: false });
            modalRef.componentInstance.data = data;
            modalRef.componentInstance.bandType = bandType;
        });
    }
    openAlertInfoIntegrate(data, bandType) {
        setTimeout(() => {
            const modalRef = this.modalService.open(ShowAlertInfoIntegrateComponent, {
                backdrop: true,
                keyboard: true,
                centered: true,
                windowClass: 'draggable-modal'
            });
            modalRef.componentInstance.data = data;
            modalRef.componentInstance.bandType = bandType;
        });
    }
    openEbsAlertInfo(data, bandType, firstName, lastName, avatar) {
        setTimeout(() => {
            const modalRef = this.modalService.open(ShowEbsalertInfoComponent, { backdrop: 'static', keyboard: false });
            modalRef.componentInstance.data = data;
            modalRef.componentInstance.bandType = bandType;
            modalRef.componentInstance.firstName = firstName;
            modalRef.componentInstance.lastName = lastName;
            modalRef.componentInstance.avatar = avatar;
        });
    }

    openMapInfo(data, beacon, accuracy) {
        setTimeout(() => {
            const modalRef = this.modalService.open(ShowMapInfoComponent, { centered: true, backdrop: true, keyboard: true, windowClass: 'draggable-modal' });
            modalRef.componentInstance.data = data;
            modalRef.componentInstance.beacon = beacon;
            modalRef.componentInstance.accuracy = accuracy;

        });
    }

    openStartInfo(message: string, route?: string) {
        setTimeout(() => {
            const modalRef = this.modalService.open(PopUpHtmlComponent, { centered: true, backdrop: 'static', keyboard: false });
            modalRef.componentInstance.modalType = 1;
            modalRef.componentInstance.message = message;

            if (route) {
                modalRef.result.then((result) => {
                    this.router.navigateByUrl(route);
                });
            }
        });
    }

    openAcceptAwait(message: string): Observable<any> {
        const response: Subject<any> = new Subject<any>();

        setTimeout(() => {

            const modalRef = this.modalService.open(PopUpComponent, { centered: true, backdrop: 'static', keyboard: false });
            modalRef.componentInstance.modalType = 1;
            modalRef.componentInstance.message = message;

            modalRef.result.then(
                (result) => response.next({ ok: true })
            ).catch(
                (reject) => response.next({ ok: false })
            );

        });
        return response;
    }

}
