import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ApiService } from './api.service';


@Injectable()
export class CaregiverService {

  constructor(
    private apiService: ApiService
  ) { }

  getCaregivers(): Observable<any> {
    return this.apiService.get('/user/showCaregiver');
  }

  getCaregiver(id): Observable<any> {
    return this.apiService.get(`/user/showCaregiver/${id}`);
  }

  addCaregiver(data): Observable<any> {
    return this.apiService.post('/user/createCaregiver', data);
  }

  editCaregiver(caregiver): Observable<any> {
    return this.apiService.put(`/user/updateCaregiver/${caregiver.id}`, caregiver);
  }

  deleteCaregiver(id): Observable<any> {
    return this.apiService.delete(`/manager/caregiver/${id}`);
  }

  workHours(data): Observable<any> {
    return this.apiService.post('/manager/caregiver/work-hours', data);
  }

  careHours(data): Observable<any> {
    return this.apiService.post('/manager/care-hours', data);
  }

  assignUser(data): Observable<any> {
    return this.apiService.post('/manager/assign-patient-to-caregiver', data);
  }

  assignAllUser(data): Observable<any> {
    return this.apiService.post('/manager/assign-all-patient-to-caregiver', data);
  }

  deleteUser(data): Observable<any> {
    return this.apiService.delete('/manager/remove-patient-from-caregiver', data);
  }

  tempFinishAlarm(data): Observable<any> {
    return this.apiService.post('/alarm/tempFinish', data);
  }

  tempEbsFinishAlarm(data): Observable<any> {
    return this.apiService.post('/alarm/tempEbsFinish', data);
  }

  finishAlarm(data): Observable<any> {
    return this.apiService.post('/alarm/finish', data);
  }

  finishAlarmEBS(data): Observable<any> {
    return this.apiService.post('/alarm/finishEBS', data);
  }

  getCaregiverToAssignProject(id): Observable<any> {
    return this.apiService.get(`/manager/available-caregivers-to-assign-project/${id}`);
  }

  getCaregiverAssignedToProject(id): Observable<any> {
    return this.apiService.get(`/manager/caregivers-assigned-to-project/${id}`);
  }


  assignCaregiver(data): Observable<any> {
    return this.apiService.post('/manager/assign-caregiver-to-project', data);
  }

  deleteCaregiverFromProject(data): Observable<any> {
    return this.apiService.post('/manager/delete-caregiver-from-project', data);
  }

  generateReport(type, form, lang): Observable<any> {
    return this.apiService.post(`/manager/generateReport/${type}`, { form, lang });
  }

  getImeiChanges(): Observable<any> {
    return this.apiService.get(`/manager/getImeiChanges`);
  }

  getHistoryOfBand(): Observable<any> {
    return this.apiService.get(`/manager/getHistoryOfBand`);
  }

  getOtherSoses(imei): Observable<any> {
    return this.apiService.get(`/getAllAlarmsNotHandled/${imei}`);
  }

  setPermissions(data): Observable<any> {
    return this.apiService.post('/manager/setPermissions', data);
  }

  getWorkHistory(managerId): Observable<any> {
    return this.apiService.get(`/manager/getWorkHistory/${managerId}`);
  }

  exportWorkHistory(managerId, lang): Observable<any> {
    return this.apiService.post(`/manager/exportWorkHistory/${lang}`, { managerId: managerId });
  }

  getCaregiverProjects(caregiverId): Observable<any> {
    return this.apiService.get(`/manager/getProjects/${caregiverId}`);
  }

  getProjectsDates(projectId): Observable<any> {
    return this.apiService.get(`/projects/getDates/${projectId}`);
  }

  getBandType(imei): Observable<any> {
    return this.apiService.get(`/manager/getBandType/${imei}`);
  }

  getManagerPrivilege(user_id): Observable<any> {
    return this.apiService.get(`/caregiver/getManagerPrivilege/${user_id}`);
  }

  getNotifsWithConnected(): Observable<any> {
    return this.apiService.get(`/manager/getNotifsWithConnected`);
  }

  markAsReadTrait(userId, traitId): Observable<any> {
    return this.apiService.post(`/manager/markAsReadTrait`, { userId: userId, traitId: traitId });
  }

}
