import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {PatientService, UserService} from '../../services';
import {CaretakerService} from '../../services/caretaker.service';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {

  @Input() calculatedHours: number;
  @Output() filterByUserMethod: EventEmitter<any> = new EventEmitter();

  constructor(
    private patientService: PatientService,
    private caretakerService: CaretakerService,
    private userService: UserService
  ) {
  }

  public apiUrl;
  public avatar;
  public caregivers = [];
  public userMe;
  isPanelVisible = false;

  ngOnInit() {
    this.userMe = this.userService.getCurrentUser().user_id;
    this.apiUrl = environment.avatar_url;
    this.getCaregivers();
  }

  getCaregivers() {
    this.caretakerService.getCaretakersCalendar(this.userMe.id).subscribe(data => {
        let dataTemp = data.data;

        dataTemp.forEach(user => {
          this.caregivers.push({
            id: user.id,
            name: (user.firstName + ' ' + user.lastName),
            isChecked: false,
            src: (user.avatar.includes('.jpg')) ? this.apiUrl + user.avatar : 'assets/images/user.svg'
          });
        });
      }, () => {});
  }

  filterByUser(id) {
    let selectedItem = this.caregivers.find(item => item.id === id);

    if (selectedItem) {
      selectedItem.isChecked = !selectedItem.isChecked;
    }

    let filteredItems = this.caregivers.filter(item => item.isChecked);
    let filteredIds = filteredItems.map(item => item.id);

    this.filterByUserMethod.emit(filteredIds);
  }

}
