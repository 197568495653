import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.scss']
})
export class DiagramComponent implements OnInit, OnChanges {

  @Input() type;
  @Input() data;
  @Input() scaleFrom;
  @Input() scaleTo;
  @Input() format = 'yyyy-MM-dd HH:mm';
  @ViewChild('canvas') canvas;
  public chart;
  public widthOfChart = 0;

  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
    if(this.data.length > 200 && this.data.length < 500){
      this.widthOfChart = 320;
    } else if(this.data.length >= 500 && this.data.length < 800){
      this.widthOfChart = 500;
    } else if(this.data.length >= 800 && this.data.length < 1000){
      this.widthOfChart = 700;
    } else if(this.data.length >= 1000){
      this.widthOfChart = 950;
    } else if(this.data.length < 250){
      this.widthOfChart = 0;
    }


    this.chart = new Chart(this.canvas.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data.map(data => this.datePipe.transform(data.x * 1000, this.format) + "  "),
        datasets: [{
          data: this.data.map(data => data.y),
          backgroundColor: this.data.map(data => data.trust_lvl ? (data.trust_lvl == 3 ? '#176193' : data.trust_lvl == 2 ? 'rgba(23,97,147,0.5)' : 'rgba(23,97,147,0.12)') : '#176193'),
          maxBarThickness: 25 // Przeniesiono tutaj
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 10
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: this.scaleFrom,
              suggestedMax: this.scaleTo
            }
          }]
        },
        tooltips: {
          backgroundColor: '#f2f2f2',
          titleFontColor: '#9b9b9b',
          bodyFontSize: 30,
          bodyFontStyle: 'bold',
          bodyFontColor: '#176193',
          xPadding: 20,
          yPadding: 20,
          displayColors: false,
          borderColor: '#e0e0e0',
          borderWidth: 1
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.data.length > 200 && this.data.length < 500){
      this.widthOfChart = 320;
    } else if(this.data.length >= 500 && this.data.length < 800){
      this.widthOfChart = 500;
    } else if(this.data.length >= 800 && this.data.length < 1000){
      this.widthOfChart = 700;
    } else if(this.data.length >= 1000){
      this.widthOfChart = 950;
    } else if(this.data.length < 250){
      this.widthOfChart = 0;
    }

    if (!changes.data.firstChange) {
      this.chart.data.labels = changes.data.currentValue.map(data => this.datePipe.transform(data.x * 1000, this.format)+" ");
      this.chart.data.datasets[0].data = changes.data.currentValue.map(data => data.y);
      this.chart.data.datasets[0].backgroundColor = changes.data.currentValue.map(data => data.trust_lvl ? (data.trust_lvl == 3 ? '#176193' : data.trust_lvl == 2 ? 'rgba(23,97,147,0.5)' : 'rgba(23,97,147,0.12)') : '#176193');

      this.chart.update();
    }
  }

}
