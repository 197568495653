import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, RouteRefreshComponent } from './shared';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'indiv-client',
    loadChildren: './indiv-client/indiv-client.module#IndivClientModule'
  },
  {
    path: 'caregivers',
    loadChildren: './caregivers/caregivers.module#CaregiversModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'observers',
    loadChildren: './observers/observers.module#ObserversModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: './settings/settings.module#SettingsModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'my-dashboard',
    loadChildren: './my-dashboard/my-dashboard.module#MyDashboardModule',
    canLoad: [AuthGuard]
  },
  {
     path: 'beacons',
     loadChildren: './beacons/beacons.module#BeaconsModule',
     canLoad: [AuthGuard]
  },
  {
    path: 'caretaker',
    loadChildren: './caretaker/caretaker.module#CaretakerModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'projects',
    loadChildren: './projects/projects.module#ProjectsModule',
    canLoad: [AuthGuard]
  },
  {
     path: 'help',
     loadChildren: './help/help.module#HelpModule'
  },
  {
    path: 'start',
    loadChildren: './start/start.module#StartModule',
  },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    canLoad: [AuthGuard]
  },
  {
    path: '',
    component: RouteRefreshComponent,
    pathMatch: 'full'
  }
];

export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(appRoutes);
