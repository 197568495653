import {Component, ViewChild, Input, AfterViewInit, ViewEncapsulation, OnChanges, SimpleChanges} from '@angular/core';
import { FormGroup } from '@angular/forms';
const noUiSlider = require('nouislider/distribute/nouislider.js');

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class SliderComponent implements AfterViewInit {

    @ViewChild('slider') slider;
    @Input('min') min;
    @Input('max') max;
    @Input('step') step;
    @Input('unit') unit;
    @Input() parentFormGroup: FormGroup;
    @Input() parentFormControl;
    noUiSlider: any;

    constructor() {
    }

    ngAfterViewInit() {
        const slider = document.getElementById(this.parentFormControl);
        noUiSlider.create(this.slider.nativeElement, {
            start: [this.parentFormGroup.get(this.parentFormControl).value],
            connect: [true, false],
            range: {
                'min': this.min,
                'max': this.max
            },
            'step': this.step
        });
        this.noUiSlider = this.slider.nativeElement.noUiSlider;
        this.noUiSlider.on('update', values => {
            if (parseInt(values[0], 10) >= this.min && parseInt(values[0], 10) <= this.max) {
              this.parentFormGroup.get(this.parentFormControl).setValue(parseInt(values[0], 10));
            } else {
              if (parseInt(values[0], 10) > this.max) { this.parentFormGroup.get(this.parentFormControl).setValue(parseInt(this.max, 10)); }
              if (parseInt(values[0], 10) < this.min) { this.parentFormGroup.get(this.parentFormControl).setValue(parseInt(this.min, 10)); }
            }
        });
    }

  getValue() {
        const value = this.parentFormGroup.get(this.parentFormControl).value;

        if (this.noUiSlider) {
            this.noUiSlider.updateOptions({
                start: value
            });
        }

        if (this.unit === 'time') {
            const hours = Math.floor(value / 60);
            const minutes = value - (hours * 60);
            return hours + ' h ' + minutes + ' min';
        } else if (this.unit) {
            return value + ' ' + this.unit;
        } else {
            return value;
        }
    }
}
