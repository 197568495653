import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import { distinctUntilChanged, map } from 'rxjs/operators';


@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService
  ) { }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      this.getMyData()
        .subscribe(
          data => {
            this.setAuth(data.data);
          },
          err => this.purgeAuth()
        );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    // Save JWT sent from server in localstorage only while login
    if (!this.jwtService.getToken()) {
      this.jwtService.saveToken(user.authentication_token);
    }
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  logout(): Observable<any> {
    return this.apiService.get('/user/logout');
  }

  attemptAuth(type, credentials): Observable<User> {
    return this.apiService.post('/' + type, credentials)
      .pipe(map(
        data => {
          this.setAuth(data.data);
          return data;
        }
      ));
  }

  checkActivationOfAccount(credentials): Observable<any> {
    return this.apiService.get('/user/checkActivationOfAccount', credentials)
  }

  retrivePass(type, credentials): Observable<User> {
    return this.apiService.post('/' + type, credentials);
  }

  getTypeOfUser(): string {
    return this.currentUserSubject.value.type;
  }

  getPrivilegeEko(): boolean {
    if (this.currentUserSubject.value.user_id && this.currentUserSubject.value.user_id.privilege) {
      return this.currentUserSubject.value.user_id.privilege.includes('eko')
    }
  }

  getPrivilegeAssignResponder(): boolean {
    if (this.currentUserSubject.value.user_id && this.currentUserSubject.value.user_id.privilege) {
      return this.currentUserSubject.value.user_id.privilege.includes('assignResponder')
    }
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  getRole(): number {
    return this.getIntRole(this.currentUserSubject.value.role);
  }

  getIntRole(role: string): number {
    switch (role) {
      case 'ROLE_API_USER':
        return 1;
      case 'ROLE_API_CAREGIVER':
        return 2;
      case 'ROLE_API_MANAGER':
        return 3;
      default:
        return 99;
    }
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<User> {
    return this.apiService
      .put('/user', { user })
      .pipe(map(data => {
        // Update the currentUser observable
        this.currentUserSubject.next(data.user);
        return data.user;
      }));
  }

  generateReport(id, dateFrom, dateTo, lang) {
    return this.apiService.post(`/generate/project/report/${lang}`, { id: id, dateFrom: dateFrom, dateTo: dateTo });
  }

  getMyData(): Observable<any> {
    return this.apiService.get('/user/me');
  }

  setPassword(type, data): Observable<any> {
    return this.apiService.put('/user/' + type, data);
  }

  checkImei(imei): Observable<any> {
    return this.apiService.get('/band/checkImei', { imei: imei });
  }

  checkImeiProjectPesel(imei, email, pesel, lang): Observable<any> {
    return this.apiService.post('/band/checkImeiProjectPesel', { imei: imei, email: email, pesel: pesel, lang: lang });
  }

  checkImeiProject(imei): Observable<any> {
    return this.apiService.get('/band/checkImeiProject', { imei: imei });
  }

  checkImeiUser(imei): Observable<any> {
    return this.apiService.get('/band/checkImeiUser', { imei: imei });
  }

  checkImeiAndCreateAccount(imei, mail): Observable<any> {
    return this.apiService.get('/band/checkImeiAndCreateAccount', { imei: imei, mail: mail });
  }

  changeImei(data): Observable<any> {
    return this.apiService.post('/manager/changeImei', data);
  }

  checkEmail(email): Observable<any> {
    return this.apiService.get('/user/emailCheck', { email: email });
  }

  checkErpRegisterEmail(email): Observable<any> {
    return this.apiService.get('/user/checkErpRegisterEmail', { email: email });
  }

  getAddress(imei): Observable<any> {
    return this.apiService.get(`/user/getAddress/${imei}`);
  }

  getObservers(): Observable<any> {
    return this.apiService.get(`/user/getObservers`);
  }

  addObserver(data): Observable<any> {
    return this.apiService.post(`/user/addObserver`, data);
  }

  editObserver(observer): Observable<any> {
    return this.apiService.put(`/user/updateObserver/${observer.id}`, observer);
  }

  getObserver(id): Observable<any> {
    return this.apiService.get(`/user/showObserver/${id}`);
  }

  deleteObserver(observer_id): Observable<any> {
    return this.apiService.delete(`/user/deleteObserver/${observer_id}`);
  }

  checkAllowChangingOnFirstLogin(hash): Observable<any> {
    return this.apiService.getDefaultController(`/checkAllowChangingOnFirstLogin/${hash}`);
  }

  getVersionApi(): Observable<any> {
    return this.apiService.getDefaultController(`/getVersionApi`);
  }

  getResponderState(): Observable<any> {
    return this.apiService.get(`/user/responderState`);
  }

  getManagerPrivilege(user_id): Observable<any> {
    return this.apiService.get(`/caregiver/getManagerPrivilege/${user_id}`);
  }

  // createPost basedon   /**
  //      * @Route("/responderEndIntervention/", methods={"POST"})
  //   */
  //  public function responderEndIntervention(UserInterface $user, Serializer $serializer, Request $request)
  //  {
  //      $parentId = $user->getParentId() ?: $user->getId();
  //      $privelege = $user->getPrivilege();
  //      $privelages = explode(',', $user->getPrivilege());

  //          // if (in_array('assignResponder', $privelages)) {


  //      $imei = $request->get('imei');
  //      $this->responderStateRepository->updateResponderState($imei, 0, null);

  //      $successOutputSerialized = $serializer->serialize(new SuccessOutput(Response::HTTP_OK, 'success'), 'json');
  //      return new JsonResponse($successOutputSerialized, Response::HTTP_OK, [], true);

  //  }

  responderEndIntervention(imei): Observable<any> {
    return this.apiService.post(`/user/responderEndIntervention`, { imei: imei });
  }

}
