import { Component, Injectable, ViewChild, OnInit, OnDestroy, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private translate: TranslateService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.translate.instant('weekdays.' + [weekday - 1]);
  }
  getMonthShortName(month: number): string {
    return this.translate.instant('months.' + [month - 1]);
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})

export class DatePickerComponent implements OnInit, OnDestroy {

  @ViewChild('d') d;
  @Input() parentFormControl: FormControl;
  @Input() maxWidth: number;
  @Input() placeholder: string = null;
  @Input() limitDate: boolean = true;
  @Input() futureDateOnly: boolean = null;
  @Input() minDateIn = null;
  @Input() maxDateIn = null;

  private subscription;
  private date = new Date();

  minDate() {
    if (this.minDateIn === null) {
      this.minDateIn = { year: this.date.getFullYear() - 100, month: this.date.getMonth() + 1, day: this.date.getDate() };
    }
  }

  maxDate() {
    if (this.maxDateIn === null) {
      if (this.limitDate) {
        this.maxDateIn = { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() };
      }
      else {
        this.maxDateIn = { year: this.date.getFullYear()+100, month: this.date.getMonth() + 1, day: this.date.getDate() };
      }
    }
  }

  minDateFuture() {
    if (this.minDateIn === null) {
      this.minDateIn = { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() };
    }
  }

  maxDateFuture() {
    if (this.maxDateIn === null){
      if (this.limitDate) {
        this.maxDateIn = { year: this.date.getFullYear()+100, month: this.date.getMonth() + 1, day: this.date.getDate() };
      }
    }
  }

  constructor(private eRef: ElementRef) {
    setTimeout(() => {
      if (this.futureDateOnly) {
        this.minDateFuture();
        this.maxDateFuture();
      } else if (!this.futureDateOnly) {
        this.minDate();
        this.maxDate();
      }
    }, 500)
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'click').subscribe((event: KeyboardEvent) => {
      if (!this.eRef.nativeElement.contains(event.target)) {
        this.d.close();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
