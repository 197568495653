import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocketService } from '../../services/socket.service';
import { Subscription } from 'rxjs/Subscription';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-ebs-alert',
  templateUrl: './ebs-alert.component.html',
  styleUrls: ['./ebs-alert.component.scss']
})
export class EbsAlertComponent implements OnInit {

    @Input() user;
    private subscription: Subscription;


    constructor(
        public activeModal: NgbActiveModal,
        private socketService: SocketService,
        private ngZone: NgZone
    ) { }

  ngOnInit() {
      this.ngZone.runOutsideAngular(() => {

          this.subscription = this.socketService.onAccept()
              .subscribe(data => {
                  this.ngZone.run(() => {

                      if (data.id == this.user.id) {
                          this.activeModal.dismiss();
                      }
                      setTimeout(() =>
                          {
                              this.socketService.checkAlarms();
                          },
                          1500);

                  });
              });

      });
  }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
