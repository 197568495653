import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService, PatientService, ValidateService } from '../../services';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-download-file-document',
  templateUrl: './download-file-document.component.html',
  styleUrls: ['./download-file-document.component.scss']
})
export class DownloadFileDocumentComponent implements OnInit {

  fileUrl;
  @Input() nameOfFile;

  constructor(
    private sanitizer: DomSanitizer,
    public router: Router,
    private translate: TranslateService,
    private patientService: PatientService,
    private modalService: ModalService,
    private validateService: ValidateService
  ) {}

  ngOnInit() {}

  downloadFile() {
    this.getDataFromDocument();
  }

  getDataFromDocument() {
    this.patientService.getDocumentFromDocumentation(this.nameOfFile).subscribe(data => {
        let dataEntry = data.data;

        let binaryData = atob(dataEntry);
        let len = binaryData.length;
        let bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([bytes.buffer], { type: 'application/pdf' });

        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = this.nameOfFile;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      () => this.modalService.open(this.translate.instant('BACKEND.COULDNT-GET-DATA')));
  }

}
