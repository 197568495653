import { Component, ElementRef, OnInit, Input, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Errors } from '../../index';
import { MapsAPILoader } from '@agm/core';

declare var google: any;

@Component({
  selector: 'app-alarm-zone',
  templateUrl: './alarm-zone.component.html',
  styleUrls: ['./alarm-zone.component.scss']
})
export class AlarmZoneComponent implements OnInit {

  @Input() parentFormGroup: FormGroup;
  lat: number;
  lng: number;
  zoom: number;
  icon;
  errors: Errors = { errors: {} };

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) {

    this.icon = {
      url: 'assets/images/customIcon.svg',
      scaledSize: {
        width: 32,
        height: 32
      }
    }

  }

  ngOnInit() {
    this.zoom = 13;
    this.lat = 52.2431;
    this.lng = 21.0165;

    this.mapsAPILoader.load().then(() => {

      if (!this.parentFormGroup.get('address').value) {
        this.setCurrentPosition();
      } else {
        this.lat = parseFloat(this.parentFormGroup.get('latitude').value);
        this.lng = parseFloat(this.parentFormGroup.get('longitude').value);
        this.zoom = 13;
      }

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.zoom = 13;
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();

          this.parentFormGroup.get('latitude').setValue(this.lat);
          this.parentFormGroup.get('longitude').setValue(this.lng);
          this.parentFormGroup.get('address').setValue(place.formatted_address);
        });
      });

    });
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 13;
        this.getAddressFromLatLang(this.lat, this.lng);
      });
    }
  }

  private getAddressFromLatLang(lat, lng) {
    const geocoder = new google.maps.Geocoder;
    const latLng = new google.maps.LatLng(lat, lng);

    geocoder.geocode({
      'location': {
        lat: lat,
        lng: lng
      }
    }, (results, status) => {
      if (status.toString() === 'OK') {
        this.parentFormGroup.get('latitude').setValue(lat);
        this.parentFormGroup.get('longitude').setValue(lng);
        this.parentFormGroup.get('address').setValue(results[0].formatted_address);
      }
    });

  }

}
