import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from './api.service';



@Injectable()
export class CaretakerService {

  constructor(
    private apiService: ApiService
  ) {
  }

  getUserFormsForManager(): Observable<any> {
    return this.apiService.get('/formsInfo');
  }

  getFormsHistory(): Observable<any> {
    return this.apiService.get('/formsHistory');
  }

  getFormUsers(formId): Observable<any> {
    return this.apiService.get(`/formUsers/${formId}`);
  }

  getFormUsersToAssign(): Observable<any> {
    return this.apiService.get(`/notAssociateUsers`);
  }

  getFormUsersToDetach(formId): Observable<any> {
    return this.apiService.get(`/formUsers/${formId}`);
  }

  addForm(credentials): Observable<any> {
    return this.apiService.post('/formTemplate', credentials);
  }

  editForm(formId, credentials): Observable<any> {
    return this.apiService.post(`/updateForm/${formId}`, credentials);
  }

  deleteForm(formId): Observable<any> {
    return this.apiService.delete(`/deleteForm/${formId}`);
  }

  getFormDetails(formId): Observable<any> {
    return this.apiService.get(`/generalFormTemplate/${formId}`);
  }

  assignUserToForm(formId, data): Observable<any> {
    return this.apiService.post(`/userForm/${formId}`, data);
  }

  detachUserToForm(formId, imei): Observable<any> {
    return this.apiService.delete(`/unsetUser/${formId}/${imei}`);
  }

  getFormsOfUser(imei): Observable<any> {
    return this.apiService.get(`/formsOfUser/${imei}`);
  }

  getFormDetail(formId): Observable<any> {
    return this.apiService.get(`/formDetail/${formId}`);
  }

  addFormSchedule(caregiverID, imei, datesArr, typeOfNotify): Observable<any> {
    return this.apiService.post(`/addFormSchedule/${caregiverID}/${imei}`, { datesArr: datesArr, typeOfNotify: typeOfNotify });
  }

  getListOfSchedules(imei): Observable<any> {
    return this.apiService.get(`/listOfSchedules/${imei}`);
  }

  getListOfSchedulesCaregiver(caregiverID): Observable<any> {
    return this.apiService.get(`/listOfSchedulesCaregiver/${caregiverID}`);
  }

  deleteFormSchedule(schedule_id): Observable<any> {
    return this.apiService.delete(`/deleteFormSchedule/${schedule_id}`);
  }

  getInfoFormSchedule(schedule_id): Observable<any> {
    return this.apiService.get(`/infoAboutFormSchedule/${schedule_id}`);
  }

  editFormSchedule(schedule_id, data): Observable<any> {
    return this.apiService.post(`/editFormSchedule/${schedule_id}`, data);
  }

  getListOfCaretakers(imei): Observable<any> {
    return this.apiService.get(`/listOfCaretakers/${imei}`);
  }

  getEventsCalendar(parentId): Observable<any> {
    return this.apiService.get(`/getEventsCalendar/${parentId}`)
  }

  getUsersCalendar(parentId): Observable<any> {
    return this.apiService.get(`/getUsersCalendar/${parentId}`)
  }

  getCaretakersCalendar(parentId): Observable<any> {
    return this.apiService.get(`/getCaretakersCalendar/${parentId}`)
  }

  getInfoAboutEvent(scheduleId): Observable<any> {
    return this.apiService.get(`/getInfoAboutEvent/${scheduleId}`)
  }

  addEventCalendar(body): Observable<any> {
    return this.apiService.post(`/addEventCalendar`, body)
  }

  editEventCalendar(scheduleId, body): Observable<any> {
    return this.apiService.put(`/editEventCalendar/${scheduleId}`, body)
  }

  deleteEventCalendar(scheduleId): Observable<any> {
    return this.apiService.delete(`/deleteEventCalendar/${scheduleId}`)
  }

  validatingEvent(mode, body): Observable<any> {
    return this.apiService.post(`/validatingEvent/${mode}`, body);
  }

  setCanceledStatus(scheduleId): Observable<any> {
    return this.apiService.postV2(`/setCanceledStatus/${scheduleId}`)
  }

  getCaretakerBillings(userId: number): Observable<any> {
    return this.apiService.get(`/getCaretakerBillings/${userId}`);
  }

  getBillingsHistory(parentId: number, startDate: string, endDate: string): Observable<any> {
    return this.apiService.get(`/getBillingsHistory/${parentId}?startDate=${startDate}&endDate=${endDate}`);
  }

  getCaretakerBillingInfo(parentId): Observable<any> {
    return this.apiService.get(`/getCaretakerBillingInfo/${parentId}`)
  }

  deleteBillingInfo(userId): Observable<any> {
    return this.apiService.delete(`/deleteBillingInfo/${userId}`)
  }

  editBillingInfo(userId, body): Observable<any> {
    return this.apiService.put(`/editBillingInfo/${userId}`, body)
  }

  exportBillingInfo(parentId, lang): Observable<any> {
    return this.apiService.post(`/exportBillingInfo/${lang}`, { parentId: parentId });
  }

  exportBillingHistory(parentId, lang): Observable<any> {
    return this.apiService.post(`/exportBillingHistory/${lang}`, { parentId: parentId });
  }

}
