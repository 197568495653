import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-validate-errors-dynamic-list',
  templateUrl: './validate-errors-dynamic-list.component.html',
  styleUrls: ['./validate-errors-dynamic-list.component.scss']
})
export class ValidateErrorsDynamicListComponent {

  @Input() parentFormControl: FormControl;

  constructor(public translate: TranslateService) { }

  get errorMessage() {
    for (const key in this.parentFormControl.errors) {
      if (this.parentFormControl.errors.hasOwnProperty(key)) {
        return this.getValidationMessage(key, this.parentFormControl.errors[key]);
      }
    }
    return null;
  }

  getValidationMessage(validator: string, validatorValue?: any) {
    const messages = {
      'required': this.translate.instant('FORMS.FIELD_IS_REQUIRED_WITHOUT_NOTICE'),
      'minlength': this.translate.instant('FORMS.MIN_LENGTH', { value: validatorValue.requiredLength }),
      'maxlength': this.translate.instant('FORMS.MAX_LENGTH', { value: validatorValue.requiredLength }),
      'numberRequired': this.translate.instant('FORMS.NUMBER_IS_REQUIRED'),
      'upperRequired': this.translate.instant('FORMS.UPPER_IS_REQUIRED'),
      'lowRequired': this.translate.instant('FORMS.LOWER_IS_REQUIRED'),
      'emailValidation': this.translate.instant('FORMS.EMAIL_NOT_VALID'),
      'emailCheck': this.translate.instant('FORMS.CHECK_EMAIL'),
      'pattern': this.translate.instant('FORMS.NOT_VALID_FIELD'),
      'matchPassword': this.translate.instant('FORMS.PASSWORD_IS_NOT_THE_SAME'),
      'matchPhones': this.translate.instant('FORMS.PHONE_NOT_MATCH'),
      'imeiValidation': this.translate.instant('FORMS.WRONG_IMEI'),
      'imeiCheck': this.translate.instant('FORMS.CHECK_IMEI'),
      'imeiTaken': this.translate.instant('FORMS.TAKEN_IMEI'),
      'IMEI_NOT_EXISTS': this.translate.instant('FORMS.CHECK_IMEI'),
      'IMEI_TAKEN': this.translate.instant('FORMS.TAKEN_IMEI'),
      'requiredTrue': this.translate.instant('FORMS.THIS_RULE_IS_OBLIGATORY'),
      'checkCharsInPassword': this.translate.instant('FORMS.LOGIN_NOT_VALID_PASSWORD'),
      'checkCharsInNewPassword': this.translate.instant('FORMS.LOGIN_NOT_VALID_PASSWORD'),
      'checkHoursFormat': this.translate.instant('FORMS.NOT_VALID_HOUR'),
      'badHoursFormat': this.translate.instant('FORMS.BAD_HOUR'),
      'badStartHoursFormat': this.translate.instant('FORMS.BAD_START_HOUR'),
      'checkLengthName': this.translate.instant('FORMS.SINGLE_LENGTH'),
      'checkAreaCode': this.translate.instant('FORMS.AREA_CODE'),
      'checkHoursDuplicate': this.translate.instant('FORMS.HOUR-DUPLICATE'),
      'FifteenMinutesFormat': this.translate.instant('FORMS.FIFTEEN_MINUTES'),
      'checkMaximumPulse': this.translate.instant('FORMS.CHECK-MAXIMUM-PULSE'),
      'wrongPesel': this.translate.instant('FORMS.WRONG-PESEL'),
      'forbiddenChar': this.translate.instant('FORMS.FORBIDDEN-CHARS'),
      'passwordMinLength': this.translate.instant('FORMS.TOO-SHORT-PASSWORD'),
    };

    return messages[validator];
  }

}
