import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit, OnDestroy {

    @Input() searchData: Array<any>;
    @Input() searchKeys: Array<string>;
    @Input() disabled = false;
    @Output() presentedResults: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
    resultData: Array<any> = [];

    searchTerm$ = new Subject<string>();

    ngOnInit() {
        this.searchTerm$
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(searchKey => {
                this.onKeySearchInput(searchKey);
            });
    }

    convertNamesFields(searchKeys, indexOfFirstName, indexOfLastName) {
        if (indexOfFirstName >= 0 && indexOfLastName >= 0) {
            this.searchKeys.push('fullName');
            this.searchKeys.push('fullNameReverse');

        }
    }

    completeFirstAndLastNames(searchKeys, searchRecord, indexOfFirstName, indexOfLastName) {
        if (indexOfFirstName >= 0 && indexOfLastName >= 0) {
            searchRecord['fullName'] = searchRecord[this.searchKeys[0]] + ' ' + searchRecord[this.searchKeys[1]];
            searchRecord['fullNameReverse'] = searchRecord[this.searchKeys[1]] + ' ' + searchRecord[this.searchKeys[0]];
        }
    }

    addImei(user) {
        if (user.band) {
            user['imei'] = user.band.imei;
        }
    }

    onKeySearchInput(searchValue) {

      const indexOfFirstName = this.searchKeys.indexOf(this.searchKeys[0]);
      const indexOfLastName = this.searchKeys.indexOf(this.searchKeys[1]);

        if (searchValue == '') {

        }
        else if (!searchValue.trim().length) {
          return;
        }

        this.convertNamesFields(this.searchKeys, indexOfFirstName, indexOfLastName);
        this.resultData = [];
        for (let j = 0; j < this.searchData.length; j++) {

            this.addImei(this.searchData[j]);
            this.completeFirstAndLastNames(this.searchKeys, this.searchData[j], indexOfFirstName, indexOfLastName);
            for (let z = 0; z < this.searchKeys.length; z++) {
                if (
                    this.searchData[j][this.searchKeys[z]].toString().toUpperCase()
                        .includes(searchValue.toString().toUpperCase())
                ) {
                    this.resultData.push(this.searchData[j]);
                }
            }
        }

        this.resultData = Array.from(new Set(this.resultData));
        (searchValue === '') ? this.presentedResults.emit(this.searchData) : this.presentedResults.emit(this.resultData);

    }

    ngOnDestroy() {
        this.searchTerm$.unsubscribe();
    }

}

