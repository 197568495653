import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PatientService} from '../../services/patient.service';
import {UserService} from '../../services/user.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-form-questionnaire',
  templateUrl: './form-questionnaire.component.html',
  styleUrls: ['./form-questionnaire.component.scss']
})
export class FormQuestionnaireComponent implements OnInit {

    @Input()id: number;
    myForm: FormGroup;

  constructor(
      public activeModal: NgbActiveModal,
      private formBuilder: FormBuilder,
      private patientService: PatientService,
      private userService: UserService,
      private translate: TranslateService
  ) {
      this.createForm();
  }

  ngOnInit() {

  }

    closeModal() {
        this.activeModal.close('Modal Closed');
    }

    private createForm() {
        this.myForm = this.formBuilder.group({
            expection: '1',
            howoften: '1',
            is_ok: '1',
            more: ''
        });
    }
    submitForm() {
        this.activeModal.close(this.myForm.value);
    }

    sendForm() {
        alert(this.translate.instant('BACKEND.THANKING-FOR-FEEDBACK'));
        this.patientService.questionnaireContact(this.myForm.get('expection').value, this.myForm.get('howoften').value,this.myForm.get('is_ok').value,this.myForm.get('more').value).subscribe(
            data => {
            },

        );
    }

}
