import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from './user.service';
import { take, tap } from 'rxjs/operators';
import 'rxjs/add/observable/of';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canLoad(route: ActivatedRouteSnapshot): Observable<boolean> {

    return this.isLoggedIn();

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    let user;
    this.userService.currentUser.subscribe(
      (userData) => {
        user = userData;
      });

    if (
      !this.isLoggedIn() ||
      this.userService.getRole() < this.userService.getIntRole(route.data.expectedRole)
    ) {
      return Observable.of(false);
    } else {
      if (route.data.privilege) {
        if (user.user_id.privilege.includes('eko')) {
          return Observable.of(true);
        } else {
          return Observable.of(false);
        }
      }
      return Observable.of(true);

    }

  }

  private isLoggedIn(): Observable<boolean> {
    return this.userService.isAuthenticated
      .pipe(
      take(1),
      tap(loggedIn => {
        if (!loggedIn) {
          this.router.navigate(['/auth/login']);
        }
      })
      );
  }

}
