import { Component, ViewChild, Input, AfterViewInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Renderer2 } from '@angular/core';

const noUiSlider = require('nouislider/distribute/nouislider.js');

@Component({
    selector: 'app-hours-bar',
    templateUrl: './hours-bar.component.html',
    styleUrls: ['./hours-bar.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class HoursBarComponent implements AfterViewInit {
    noUiSlider: any;
    @Input() barId;
    @ViewChild('slider') slider;
    @Input() dayOfWeek;
    @Input() dayNumber;
    @Input() rangeFirstFrom;
    @Input() rangeFirstTo;
    @Input() rangeSecondFrom;
    @Input() rangeSecondTo;
    @Input() enabled;
    @Input() mode;
    @Input() steps;
    @Output() updatedHours: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() removeDay: EventEmitter<Object> = new EventEmitter<Object>();
    public range: string;

    constructor(private renderer2: Renderer2) {
    }

    ngAfterViewInit() {
        const slider = document.getElementById(this.barId);
        noUiSlider.create(this.slider.nativeElement, {
            start: [
                this.convertTimeToValue(this.rangeFirstFrom),
                this.convertTimeToValue(this.rangeFirstTo),
                this.convertTimeToValue(this.rangeSecondFrom),
                this.convertTimeToValue(this.rangeSecondTo)
            ],
            connect: [false, true, false, true, false],
            behaviour: 'drag-tap',
            range: {
                'min': 0,
                'max': 1439
            },
            'step': this.steps,
        });


        this.noUiSlider = this.slider.nativeElement.noUiSlider;
        this.noUiSlider.target.hidden = !this.enabled;
        this.noUiSlider.on('update', (values, handle) => {
            this.convertValuesToTime(values, handle);
        });

        if (this.mode === 'caregiver') {
            this.renderer2.setAttribute(this.slider.nativeElement, 'disabled', 'true');
        }
    }

    private convertTimeToValue(hourlyFormat: string): number {
        const hours = parseInt(hourlyFormat.substr(0, hourlyFormat.indexOf(':')), 10);
        const minutes = parseInt(hourlyFormat.substr(hourlyFormat.indexOf(':') + 1, hourlyFormat.length), 10);
        return hours * 60 + minutes;
    }


    private convertValuesToTime(values, handle): void {

        this.rangeFirstFrom = this.formatTime(values[0]);
        this.rangeFirstTo = this.formatTime(values[1]);
        this.rangeSecondFrom = this.formatTime(values[2]);
        this.rangeSecondTo = this.formatTime(values[3]);
        let message;

        // jeden ciag pracowania
        if (values[1] === values[2] || values[1] === values[2] === values[3] || values[0] === values[1] === values[2]) {

            message = this.rangeFirstFrom + ' - ' + this.rangeSecondTo;
            setTimeout(() => this.range = '(' + this.rangeFirstFrom + ' - ' + this.rangeSecondTo + ')');

            this.updatedHours.emit({
                type: 1,
                day: this.dayNumber,
                from: this.rangeFirstFrom,
                to: this.rangeSecondTo,
                enabled: this.enabled
            });

        } else {

            if (this.rangeFirstFrom === this.rangeFirstTo && this.rangeSecondFrom === this.rangeSecondTo) {
                message = '00:00 - 00:00';
            } else if (this.rangeFirstFrom === this.rangeFirstTo) {
                message = this.rangeSecondFrom + ' - ' + this.rangeSecondTo;
            } else if (this.rangeSecondFrom === this.rangeSecondTo) {
                message = this.rangeFirstFrom + ' - ' + this.rangeFirstTo;
            } else {
                message = this.rangeFirstFrom + ' - ' + this.rangeFirstTo + ') (' + this.rangeSecondFrom + ' - ' + this.rangeSecondTo;
            }
            setTimeout(() => this.range = '(' + message + ')');

            this.updatedHours.emit({
                type: 2,
                day: this.dayNumber,
                fromFirst: this.rangeFirstFrom,
                toFirst: this.rangeFirstTo,
                fromSecond: this.rangeSecondFrom,
                toSecond: this.rangeSecondTo,
                enabled: this.enabled
            });

        }

    }

    private formatTime(minutes): string {
        let hour, minute;

        hour = Math.floor(minutes / 60);
        minute = minutes - (hour * 60);

        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minute < 10) {
            minute = '0' + minute;
        }

        return hour + ':' + minute;
    }

    public setEnabled(): void {
        this.enabled = true;
        this.noUiSlider.target.hidden = !this.enabled;
        this.noUiSlider.set([0, 0, 1439, 1439]);
    }

    public disableEnabled(): void {
        this.enabled = false;
        this.noUiSlider.target.hidden = !this.enabled;
        this.noUiSlider.set([0, 0]);
    }


}
