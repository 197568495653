import {Component, Injectable, ViewChild, OnInit, OnDestroy, ElementRef, Input, Output, EventEmitter, HostListener} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/fromEvent';
import {MatDatepicker} from '@angular/material';

@Injectable()
// export class CustomDatepickerI18n extends NgbDatepickerI18n {
//
//   constructor(private translate: TranslateService) {
//     super();
//   }
//
//   getWeekdayShortName(weekday: number): string {
//     return this.translate.instant('weekdays.' + [weekday - 1]);
//   }
//   getMonthShortName(month: number): string {
//     return this.translate.instant('months.' + [month - 1]);
//   }
//   getMonthFullName(month: number): string {
//     return this.getMonthShortName(month);
//   }
// }

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss']
})
export class RightPanelComponent implements OnInit {

  @ViewChild('d') d;
  @ViewChild(MatDatepicker) picker: MatDatepicker<Date>;
  @Output() chosenDate: EventEmitter<any> = new EventEmitter();
  @Output() togglePanel: EventEmitter<any> = new EventEmitter();
  @Input() parentFormControl: FormControl;
  @Input() firstClicked;

  selectedDate: Date | null = null;

  ngOnInit() {
    setTimeout(() => this.picker.open(), 0);
  }

  onDateChange() {
    this.chosenDate.emit(this.selectedDate);
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    const clickTarget = event.target as HTMLElement;

    if (this.firstClicked) {
      if (!clickTarget.closest('.mat-datepicker-content')) {
        this.firstClicked = false;
      }
    } else {
      if (!clickTarget.closest('.mat-datepicker-content')) {
        this.firstClicked = false;
        this.togglePanel.emit(false);
      }
    }

  }


}
