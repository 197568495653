import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PatientService, SocketService, UserService} from '../services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  constructor(
      private userService: UserService,
      private patientService: PatientService,
      private translateService: TranslateService,
      private ngZone: NgZone,
      private fb: FormBuilder,
      private socketService: SocketService
  ) {
    this.form = this.fb.group({
      'note': '',
      'hideNotif': '',
    });
  }

  public isSidePanelShouldUse = false;
  public notifications;
  public isLoading;
  showDetail = false;
  textNote = '';
  sideShow: boolean;
  form: FormGroup;
  userMe;
  timeToGetNewNotifs: number = 40;
  interval;


  ngOnInit( ) {

    this.checkIfManagerHasProjectConnected();

    if (window.localStorage['sideShow'] == 'true') {
      this.sideShow = true;
    } else {
      this.sideShow = false;
    }

    this.getNotifications();
    this.cyclicGettingNotif();
    this.socketInitialize();
    this.userMe = this.userService.getCurrentUser().user_id;
  }

  submitForm()
  { }



  getNotifications(){
    this.patientService.getSideNav().subscribe(data => {
      if (data && Object.keys(data).length > 0) {
        this.ngZone.run(() => {
          this.notifications = data.data;
          this.isLoading = false;
        });
      }
    });
  }



  startHandleNotif(idNotif, indexdArray){

    if(this.notifications[indexdArray].handled != 1) {

      this.notifications[indexdArray].handled = 1;
      this.patientService.startHandleSideNotif(idNotif).subscribe(data => {
      });

    } else {
      if (this.notifications[indexdArray].handled_by == this.userMe.id) {
        this.isLoading = true;
        let hideNotif = 0;

        if (this.form.get('hideNotif').value == true)
          hideNotif = 1;

        this.patientService.handleSideNotif(idNotif, this.form.get('note').value, hideNotif).subscribe(data => {
        });

        this.ngZone.run(() => {
          this.notifications[indexdArray].deleted = true;
          //delete this.notifications[indexdArray];
          this.isLoading = false;
        });
      }
    }
  }



  viewChange(){

    this.ngZone.run(() => {
      window.localStorage['sideShow'] = !this.sideShow;
      this.sideShow = !this.sideShow;
    });
  }

  socketInitialize(){

    this.socketService.handleSideNotif().subscribe(
        data=> {
            this.ngZone.run(() => {
              for (let index = 0; index < this.notifications.length; index++) {

                if (this.notifications[index]['id'] == data.notifID) {
                  this.notifications[index].deleted = true;
                }
              }
            });
        });


    this.socketService.startHandleSideNotif().subscribe(
        data=> {
          this.ngZone.run(() => {
            for (let index = 0; index < this.notifications.length; index++) {

              if (this.notifications[index]['id'] == data.notifID) {
                this.notifications[index]['handled_by'] = data.data.handled_by;
                this.notifications[index]['handled'] = 1;
              }
            }
          });
        });
  }



  cyclicGettingNotif() {
    this.interval = setInterval(() => {
      if(this.timeToGetNewNotifs > 0) {
        this.timeToGetNewNotifs--;
      } else {
        this.timeToGetNewNotifs = 40;
        this.ngZone.run(() => {
          this.getNotifications();
        });
      }
    },1000)
  }



  pauseTimer() {
      clearInterval(this.interval);
  }



  ngOnDestroy() {
    this.pauseTimer();
  }


  checkIfManagerHasProjectConnected() {
    this.patientService.checkIfManagerHasProject().subscribe(
        data=> {
          this.ngZone.run(() => {
              if(data.properly == 'YES')
              {
                this.isSidePanelShouldUse = true;
              }
          });
        });


  }



}
