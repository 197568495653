import { Component, OnInit, Input } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {PatientService} from '../../services/patient.service';
import {UserService} from '../../services/user.service';
import {ModalService} from '../../services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent implements OnInit {

    @Input()id: number;
    myForm: FormGroup;
    user = [];

  constructor(
      public activeModal: NgbActiveModal,
      private formBuilder: FormBuilder,
      private patientService: PatientService,
      private userService: UserService,
      private translate: TranslateService,
      // private modalService: ModalService,
  ) {
  }

  ngOnInit() {
      this.createForm();
      this.patientService.getUserContact().subscribe(
          data => {
              this.user = data.data;
              const mail_html = <HTMLInputElement>document.getElementById('mail');
              const phone_html = <HTMLInputElement>document.getElementById('phone');

              // mail_html.value = this.user['email'];
              if(this.user['phone'])
              phone_html.value =  this.user['phone'];
          }
      );
  }

    closeModal() {
        this.activeModal.close('Modal Closed');
    }

    private createForm() {
        this.myForm = this.formBuilder.group({
            mail: [null, [Validators.required]],
            phone: [null, [Validators.required, Validators.minLength(9)]],
            problem: 'pomoctechniczna',
            contact: 'telefon'
        });
    }

    submitForm() {
        this.activeModal.close(this.myForm.value);
    }

    sendForm() {
      const mail_html = <HTMLInputElement>document.getElementById('mail');
      const phone_html = <HTMLInputElement>document.getElementById('phone');

      let mail_now = mail_html.value;
      let phone_now = phone_html.value;

      if (!mail_now) mail_now = 'Nie podano maila';
      if (!phone_now) phone_now = 'Nie podano telefonu';
      alert(this.translate.instant('BACKEND.CONFIRM-OF-SEND-FORM'));
      this.patientService.formContact(mail_now, phone_now,this.myForm.get('problem').value,this.myForm.get('contact').value).subscribe(
          data => {
            // this.modalService.open(this.translate.instant('BACKEND.COULDNT_SAVE_ZONE'));
              //this.modalService.open(this.translate.instant(''));
          },
      );
    }


}
