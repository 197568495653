import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ValidateService, DateService } from '../../services';

@Component({
  selector: 'app-range-date-picker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss']
})
export class RangeDatePickerComponent implements OnInit {
  @Input() rangeFromDate: string;
  @Input() rangeToDate: string;
  @Output() selectRange = new EventEmitter();
  public dateRangeForm: FormGroup;
  @ViewChild('range') rangeDropdown: NgbDropdown;

  constructor(
    private fb: FormBuilder,
    private validateService: ValidateService,
    private dateService: DateService
  ) {
    this.dateRangeForm = this.fb.group({
      'fromDate': ['', [Validators.required, Validators.minLength(2)]],
      'toDate': ['', [Validators.required, Validators.minLength(2)]]
    });
  }

  ngOnInit() {
    if (this.rangeFromDate) {
      this.dateRangeForm.patchValue({ fromDate: this.dateService.fromModel(this.rangeFromDate) });
    }
    if (this.rangeToDate) {
      this.dateRangeForm.patchValue({ toDate: this.dateService.fromModel(this.rangeToDate) });
    }
  }

  public submitForm(e): void {
    if (this.dateRangeForm.valid) {
      const fromDate = this.dateRangeForm.get('fromDate').value;
      const toDate = this.dateRangeForm.get('toDate').value;

      const data = {
        fromDate: this.dateService.toModel(fromDate),
        toDate: this.dateService.toModel(toDate)
      };

      this.selectRange.emit(data);
      this.rangeDropdown.close();
    } else {
      this.validateService.validateAllFormFields(this.dateRangeForm);
      e.preventDefault();
      e.stopPropagation();
    }
  }
}
