import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Errors } from './models';
import { ModalService } from './services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-field-errors',
    templateUrl: './field-errors.component.html',
    styleUrls: ['./field-errors.component.scss']
})

export class FieldErrorsComponent {
    formattedErrors: string;

    @Input() parentFormControl: FormControl;

    @Input()
    field;
    @Input()
    set errors(errorField: Errors) {
        try {
            if (typeof (errorField.errors[this.field]) === 'object') {
                this.formattedErrors = this.changeExpression(errorField.errors[this.field]);
            } else if (this.field === 'modal' && typeof (errorField.errors) === 'string') {
                this.modalService.open(
                    this.translate.instant('BACKEND.' + this.changeExpressionString(errorField.errors))
                );
            } else if (this.field === 'modal' && typeof (errorField.errors) === 'object') {
                this.modalService.open(
                    this.translate.instant('BACKEND.' + this.changeExpressionString(errorField.errors[0]))
                );
            } else {
                this.formattedErrors = '';
            }
        } catch (err) { }
    }

    get errorField() { return this.formattedErrors; }

    constructor(private modalService: ModalService, public translate: TranslateService) { }

    changeExpression(error) {
        for (let i = 0; i < error.length; i++) {
            error[i] = error[i].replace(/\s/g, '_').toUpperCase();
        }
        return error;
    }

    changeExpressionString(error: string) {
        return error.replace(/\s/g, '_').toUpperCase();
    }

    clearBackErrors() {
        if (this.formattedErrors !== '') {
            setTimeout(() => {
                this.formattedErrors = '';
            });
        }
    }
}

