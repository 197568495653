import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from './services';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-route-refresh',
  template: '',
  styles: []
})

export class RouteRefreshComponent implements OnInit, OnDestroy {

  private subscription;

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.subscription = this.userService.isAuthenticated
      .pipe(distinctUntilChanged())
      .subscribe(
        (isAuthenticated) => {
          if (isAuthenticated) {
            if (this.userService.getRole() === 1) {
              this.router.navigateByUrl('/users/user/' + this.userService.getCurrentUser().user_id.imei);
            } else
            if (this.userService.getRole() === 2) {
              this.router.navigateByUrl('/users');
            } else if (this.userService.getRole() === 3) {
              this.router.navigateByUrl('/caregivers');
            }
          } else {
            this.router.navigateByUrl('auth/login');
          }
        }
      );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
