import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { PopUpComponent } from './shared/components/pop-up/pop-up.component';
import { SosAlertComponent } from './shared/components/sos-alert/sos-alert.component';
import { EbsAlertComponent } from './shared/components/ebs-alert/ebs-alert.component';
import { ShowAlertInfoComponent } from './shared/components/show-alert-info/show-alert-info.component';
import { ShowAlertInfoIntegrateComponent } from './shared/components/show-alert-info-integrate/show-alert-info-integrate.component';
import { ShowEbsalertInfoComponent } from './shared/components/show-ebsalert-info/show-ebsalert-info.component';
import { ShowMapInfoComponent } from './shared/components/show-map-info/show-map-info.component';
import { DatePipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import 'hammerjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE, MatFormFieldModule } from '@angular/material';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FormModalComponent } from './shared/components/form-modal/form-modal.component';
import { FormQuestionnaireComponent } from './shared/components/form-questionnaire/form-questionnaire.component';
// import { DraggableDirective } from './shared/directives/draggable.directive';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
} from '@angular/material';

import { AppComponent } from './app.component';

import {
    ApiService,
    AuthGuard,
    FooterComponent,
    HeaderComponent,
    JwtService,
    SharedModule,
    TagsService,
    UserService,
    ModalService,
    ValidateService,
    DateService,
    CaregiverService,
    PatientService,
    NotificationService,
    SocketService,
    HttpTokenInterceptor,
} from './shared';
import { PopUpHtmlComponent } from './shared/components/pop-up-html/pop-up-html.component';
import { CaretakerService } from './shared/services/caretaker.service';
import { CustomDatepickerI18n } from './shared/components/date-picker/date-picker.component';

const rootRouting: ModuleWithProviders = RouterModule.forRoot([]);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        // DraggableDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        rootRouting,
        SharedModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatChipsModule,
        MatDatepickerModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        BrowserAnimationsModule,
        MatExpansionModule,
        MatCheckboxModule,
        NgxMaskModule.forRoot(),
        TextMaskModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgbModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDx9i0N-e0Y__RkTUUdjr4ToR-g-n7wQ0w',
            libraries: ['places'],
        }),
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
        { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        ApiService,
        AuthGuard,
        JwtService,
        TagsService,
        UserService,
        ModalService,
        ValidateService,
        DateService,
        CaregiverService,
        PatientService,
        NotificationService,
        SocketService,
        CaretakerService,
        DatePipe,
    ],
    entryComponents: [
        PopUpComponent,
        PopUpHtmlComponent,
        SosAlertComponent,
        EbsAlertComponent,
        ShowAlertInfoComponent,

        ShowAlertInfoIntegrateComponent,
        ShowEbsalertInfoComponent,
        ShowMapInfoComponent,
        FormModalComponent,
        FormQuestionnaireComponent,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
