import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';

import { JwtService, UserService, ModalService } from '../services';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private jwtService: JwtService,
    private userService: UserService,
    private modalService: ModalService,
    private translateService: TranslateService,
    public router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Content-Type': 'application/json'
    };

    const token = this.jwtService.getToken();

    if (token) {
      headersConfig['X-Access-Token'] = token;
    }

    const request = req.clone({ setHeaders: headersConfig });

    return next.handle(request).do((event: HttpEvent<any>) => { }, (err: any) => {
      if (err instanceof HttpErrorResponse) {

        if (err.error.status === 403) {
          this.userService.purgeAuth();

          if (!err.url.includes('/user/me')) {
            this.router.navigateByUrl('/auth/login');
          }
        } else if (err.status === 500) {
          this.modalService.open(this.translateService.instant('BACKEND.SOMETHING-WRONG'));
        }

      }
    });

  }
}
