import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {

  @Input() options;
  @Output() selectOption = new EventEmitter();

  public currentOption: String;

  constructor() { }

  ngOnInit() {
    this.currentOption = this.options[0];
  }

  chooseOption(option: String) {
    this.currentOption = option;
    this.selectOption.emit(this.currentOption);
  }

}
