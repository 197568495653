import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from '../../services/patient.service';
import { UserService } from '../../services/user.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
    selector: 'app-show-alert-info',
    templateUrl: './show-alert-info.component.html',
    styleUrls: ['./show-alert-info.component.scss']
})
export class ShowAlertInfoComponent implements OnInit {

    @Input() data;
    @Input() bandType;
    public handledTime: string;
    public showNote = false;
    public userMe;
    public addAlarmForm: FormGroup;

    constructor(public activeModal: NgbActiveModal,
        private patientService: PatientService,
        private location: Location,
        private fb: FormBuilder,
        private userService: UserService,) {
        this.addAlarmForm = this.fb.group({
            'descId': '',
            'extraNote': ['', [Validators.required, Validators.minLength(4)]],
        });

    }



    ngOnInit() {
        if (this.data) {
            this.handledTime = this.calculateHandleTime(this.data.handledTime);
        }

        this.userMe = this.userService.getCurrentUser().user_id;

    }

    calculateHandleTime(handleTime) {

        if (handleTime < 60) {
            return handleTime + 's';
        } else if (handleTime >= 60) {
            return Math.floor(handleTime / 60) + 'm ' + Math.floor(handleTime % 60) + 's';
        }

    }

    close() {
        this.activeModal.close();
    }

    addExtraNote() {

        if (this.addAlarmForm.valid) {
            var note = <HTMLInputElement>document.getElementById('extraNote');

            var today = new Date().toLocaleString();


            note.value = today + ' - ' + this.userMe['first_name'] + ' ' + this.userMe['last_name'] + ' \n' + note.value;

            this.addAlarmForm.patchValue({
                descId: this.data.descId,
                extraNote: note.value,
            });

            const credentials = this.addAlarmForm.value;

            this.patientService.addExtraNoteToAlarm(credentials).subscribe(
                extra => {
                    this.showNote = !this.showNote;

                    if (this.data.description.extraNote) {
                        this.data.description.extraNote = this.data.description.extraNote + '\n\n' + note.value;
                    } else {
                        this.data.description.extraNote = note.value;
                    }

                    note.value = ' ';
                    this.addAlarmForm.get('extraNote').markAsUntouched();
                    this.addAlarmForm.patchValue({
                        descId: this.data.descId,
                        extraNote: '',
                    });
                }
            );
        }
    }


}
