import { AbstractControl } from '@angular/forms';
import { UserService } from '../services/user.service';

import { Observable } from 'rxjs';
import { timer } from 'rxjs/observable/timer';
import { map, switchMap, catchError } from 'rxjs/operators';

export class ValidateImeiNotTaken {
  static createValidator(userService: UserService) {
    return (control: AbstractControl) => {

      return timer(500).pipe(
        switchMap(() => userService.checkImei(control.value)),
        map(res => {
          return res ? null : { imeiCheck: true };
        }),
        catchError(error => {
          return Observable.of({ [error.data]: true });
        })
      );

    };
  }
}
