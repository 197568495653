import { AbstractControl } from '@angular/forms';
import { UserService } from '../services/user.service';

import { Observable } from 'rxjs';
import { timer } from 'rxjs/observable/timer';
import { map, switchMap, catchError } from 'rxjs/operators';

export class ValidateEmailNotTaken {
  static createValidator(userService: UserService) {
    return (control: AbstractControl) => {

      return timer(500).pipe(
        switchMap(() => {
          if (!control.disabled && control.value && !control.value.endsWith('@sidly')) {
            return userService.checkEmail(control.value);
          } else {
            return 'ok';
          }
        }),
        map(res => {
          return res ? null : { emailCheck: true };
        }),
        catchError(error => {
          return Observable.of({ emailCheck: true });
        })
      );

    };
  }
  static createErpValidator(userService: UserService) {
    return (control: AbstractControl) => {

      return timer(500).pipe(
        switchMap(() => {
          if (!control.disabled && control.value && !control.value.endsWith('@sidly')) {
            return userService.checkErpRegisterEmail(control.value);
          } else {
            return 'ok';
          }
        }),
        map(res => {
          return res ? null : { emailCheck: true };
        }),
        catchError(error => {
          return Observable.of({ emailCheck: true });
        })
      );

    };
  }
}
