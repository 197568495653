import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService, ModalService, NotificationService, PatientService, SocketService } from '../../services';
import { environment } from '../../../../environments/environment';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from 'rxjs/Notification';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TimerObservable } from 'rxjs/observable/TimerObservable';

const io = require('socket.io-client/dist/socket.io.js');
const webNotification = require('simple-web-notification/web-notification.js');

interface Alert {
    type: string;
    message: string;
    timestamp: Date;
}

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

    private one$: Subscription;
    private two$: Subscription;
    private twoEBS$: Subscription;
    private three$: Subscription;
    private four$: Subscription;
    private five$: Subscription;
    private six$: Subscription;
    private routeSubscription: Subscription;
    private flag = true;
    public alerts: Alert[] = [];

    constructor(
        private userService: UserService,
        private modalService: ModalService,
        private notificationService: NotificationService,
        private patientService: PatientService,
        private socketService: SocketService,
        private ngZone: NgZone,
        private translate: TranslateService,
        private router: Router
    ) { }

    ngOnInit() {

        this.one$ = this.userService.isAuthenticated
            .pipe(distinctUntilChanged())
            .subscribe(data => {
                if (data) {

                    this.ngZone.runOutsideAngular(() => {

                        TimerObservable.create(0, 30000).subscribe(() => {
                            this.clearOldAlerts();
                        });

                        this.socketService.init(this.userService.getCurrentUser().user_id.id);

                        this.two$ = this.socketService.onAlert()
                            .subscribe(user => {
                                this.ngZone.run(() => {
                                    this.notificationService.addUserAlarm(user);
                                    this.modalService.openSosAlert(user);
                                    this.createNotification(user);
                                });
                            });

                        this.twoEBS$ = this.socketService.onAlertEBS()
                            .subscribe(user => {
                                this.ngZone.run(() => {
                                    if (this.flag) {
                                        this.notificationService.addUserEBSAlarm(user);
                                        this.modalService.openSensorAlert(user);
                                        this.createNotification(user);
                                    }
                                });
                            });

                        this.three$ = this.socketService.onAccept()
                            .subscribe(user => {
                                this.ngZone.run(() => {
                                    this.notificationService.deleteUserAlarm(user);
                                });
                            });

                        this.four$ = this.socketService.onNotification()
                            .subscribe(user => {
                                this.ngZone.run(() => {
                                    this.createNotificationStandard(user);
                                });
                            });
                        this.five$ = this.socketService.snackbarAH().subscribe(datum => {
                            //this.audio.loop = false;
                            //this.audio.play();
                            this.alerts.push({
                                type: 'warning',
                                message: this.translate.instant('CAREGIVER.USER-CONFIRMED-ALARM', { name: datum.name }),
                                timestamp: new Date()
                            });
                        });

                        this.six$ = this.socketService.snackbar().subscribe(datum => {
                            if (this.router.url.includes('users/sos-request')) {
                                this.alerts.push({
                                    type: 'danger',
                                    message: this.translate.instant('CONNECTING-SOON.MESSAGE', { name: datum.name, typeOfAlarm: this.translate.instant(`CONNECTING-SOON.${datum.typeOfAlarm}`) }),
                                    timestamp: new Date()
                                });
                            }
                        });
                    });

                } else {
                    this.logoutNotifications();
                }
            });

    }

    createNotification(user) {
        webNotification.showNotification('SIDLYCARE ALERT', {
            body: user.last_name + ' ' + user.first_name,
            icon: 'assets/images/favicon.png',
            onClick: function onNotificationClicked() {
            },
            autoClose: 600000
        }, function onShow(error, hide) {
            /*
            if (error) {
                //window.alert('Unable to show notification: ' + error.message);
            }
            */
        });
    }

    clearOldAlerts() {
        const now = new Date();
        this.alerts = this.alerts.filter(alert => {
            const timeDifference = now.getTime() - alert.timestamp.getTime();
            return timeDifference <= 30000;
        });
    }

    //Prog tetna
    createNotificationStandard(user) {
        webNotification.showNotification('SIDLYCARE ALERT', {

            body: user.userDataPack.last_name + ' ' + user.userDataPack.first_name + ': ' + this.translate.instant('BACKEND.' + user.message),
            icon: 'assets/images/favicon.png',
            onClick: function onNotificationClicked() {
            },
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            autoClose: 600000
        }, function onShow(error, hide) {
            /*
            if (error) {
                //window.alert('Unable to show notification: ' + error.message);
            }
            */
        });
    }

    logoutNotifications() {
        if (this.two$) {
            this.two$.unsubscribe();
        }
        if (this.three$) {
            this.three$.unsubscribe();
        }
        if (this.four$) {
            this.four$.unsubscribe();
        }
        this.notificationService.clean();

        this.socketService.close();
    }

    close(alert: Alert) {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }

    ngOnDestroy() {
        if (this.one$) {
            this.one$.unsubscribe();
        }
        this.logoutNotifications();
    }
}
