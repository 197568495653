import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import * as moment from 'moment';

@Injectable()
export class PatientService {

  constructor(
    private apiService: ApiService
  ) {
  }

  getPatients(): Observable<any> {
    return this.apiService.get('/patient/my');
  }

  getPatientsShortInfo(page, count): Observable<any> {
    return this.apiService.get(`/patient/myShortInfo?page=${page}&count=${count}`);
  }

  getPatient(imei): Observable<any> {
    return this.apiService.get(`/patient/${imei}`);
  }

  getPatientIntegrate(imei): Observable<any> {
    return this.apiService.get(`/patientIntegrate/${imei}`);
  }

  getPatientToEdit(imei): Observable<any> {
    return this.apiService.get(`/manager/patient-edit-initial-data/${imei}`);
  }

  getPatientCardToEdit(imei): Observable<any> {
    return this.apiService.get(`/patientCard/${imei}`);
  }

  addPatient(data): Observable<any> {
    return this.apiService.post('/manager/patient', data);
  }

  editPatient(imei, data): Observable<any> {
    return this.apiService.patch(`/manager/patient/${imei}`, data);
  }

  editPatientCard(imei, data): Observable<any> {
    return this.apiService.patch(`/patientCard/create/${imei}`, data);
  }

  deletePatient(id): Observable<any> {
    return this.apiService.delete(`/manager/patient/${id}`);
  }

  getPatientsToAssign(caregiver): Observable<any> {
    return this.apiService.get(`/manager/available-patient-to-assign/${caregiver}`);
  }

  getAlarm(imei, type): Observable<any> {
    return this.apiService.get(`/patient/${imei}/alarm/${type}`);
  }

  getPatientIntegrateAlarm(imei): Observable<any> {
    return this.apiService.get(`/patientIntegrateAlarm/${imei}`);
  }

  getAlarmEBS(imei, type): Observable<any> {
    return this.apiService.get(`/patient/${imei}/alarmEBS/${type}`);
  }

  getDiagramStats(imei, fromDate, toDate): Observable<any> {
    return this.apiService.get(`/patient/${imei}/stats?fromDate=${fromDate}&toDate=${toDate}`);
  }

  getMedicines(imei): Observable<any> {
    return this.apiService.get(`/manager/patient/${imei}/drugs`);
  }

  getMedicinesSchedule(imei): Observable<any> {
    return this.apiService.get(`/manager/patient/${imei}/medicationSchedules`);
  }

  getMedicine(id): Observable<any> {
    return this.apiService.get(`/manager/patient/drugs/${id}`);
  }

  getMedicineSchedule(id): Observable<any> {
    return this.apiService.get(`/manager/patient/medicationSchedules/${id}`);
  }

  addMedicine(data): Observable<any> {
    return this.apiService.post('/manager/patient/drug', data);
  }

  addMedicationSchedule(data): Observable<any> {
    return this.apiService.post('/manager/patient/medicationSchedule', data);
  }

  editMedicine(id, data): Observable<any> {
    return this.apiService.post(`/manager/patient/drug/${id}`, data);
  }

  editMedicineSchedule(id, data): Observable<any> {
    return this.apiService.post(`/manager/patient/medicationSchedule/${id}`, data);
  }

  deleteMedicine(id): Observable<any> {
    return this.apiService.delete(`/manager/patient/drug/${id}`);
  }

  deleteMedicineSchedule(id): Observable<any> {
    return this.apiService.delete(`/manager/patient/medicationSchedule/${id}`);
  }

  generateRaport(imei, data): Observable<any> {
    return this.apiService.post(`/patient/report/${imei}`, data);
  }

  generateRaportTMP(data): Observable<any> {
    return this.apiService.post(`/patient/reportTMP/`, data);
  }


  handleNotif(id): Observable<any> {
    return this.apiService.get(`/notification-board2/${id}`);
  }

  handleAllNotif(): Observable<any> {
    return this.apiService.get(`/my-notif-handleall`);
  }

  getNotif(limit): Observable<any> {
    return this.apiService.get(`/my-notif/${limit}`);
  }

  getNewestNotif(newestId): Observable<any> {
    return this.apiService.get(`/my-newest-notif/${newestId}`);
  }

  getForDateNotif(date, limit): Observable<any> {
    return this.apiService.get(`/my-notif-fordate/${date}/${limit}`);
  }

  getForReasonNotif(reason, limit): Observable<any> {
    return this.apiService.get(`/my-notif-forreason/${reason}/${limit}`);
  }

  checkIfNewHandle(): Observable<any> {
    return this.apiService.get(`/my-notif-checkHandle`);
  }

  formContact(mail, phone, reason, type): Observable<any> {
    return this.apiService.get(`/contact/${mail}/${phone}/${reason}/${type}`);
  }

  questionnaireContact(expection, howoften, isok, more): Observable<any> {
    return this.apiService.get(`/questionnaire/${expection}/${howoften}/${isok}/${more}`);
  }

  getUserContact(): Observable<any> {
    return this.apiService.get(`/phoneandmail/`);
  }

  //BEACONS

  getBeacons(): Observable<any> {
    return this.apiService.get(`/get-beacons`);
  }

  getBeaconFromMac(mac): Observable<any> {
    return this.apiService.get(`/getBeacon/mac/${mac}`);
  }

  getBeacon(id): Observable<any> {
    return this.apiService.get(`/get-beacon/${id}`);
  }

  editBeacon(id, mac, name, address, lat, lon): Observable<any> {
    return this.apiService.get(`/edit-beacon/${id}/${mac}/${name}/${address}/${lat}/${lon}`);
  }

  deleteBeacon(id): Observable<any> {
    return this.apiService.delete(`/delete-beacon/${id}`);
  }

  addBeacon(data): Observable<any> {
    return this.apiService.post('/beacon-create', data);
  }

  addBeacon2(mac, name, address, lat, lon): Observable<any> {
    return this.apiService.get(`/create-beacon/${mac}/${name}/${address}/${lat}/${lon}`);
  }


  //EBS

  getEbs(imei): Observable<any> {
    return this.apiService.get(`/get-ebses/${imei}`);
  }

  addEbs(imei, data): Observable<any> {
    return this.apiService.post(`/addSensor/${imei}`, data);
  }

  editEbs(data): Observable<any> {
    return this.apiService.post(`/editSensor`, data);
  }

  deleteEbs(sn): Observable<any> {
    return this.apiService.delete(`/delete-ebs/${sn}`);
  }

  checkSn(sn): Observable<any> {
    return this.apiService.get(`/checkSN/${sn}`);
  }

  //communicate network

  getDiseases(imei): Observable<any> {
    return this.apiService.get(`/getDiseases/${imei}`);
  }

  getContacts(imei): Observable<any> {
    return this.apiService.get(`/getContacts/${imei}`);
  }

  editContacts(imei, data): Observable<any> {
    return this.apiService.patch(`/editContacts/${imei}`, data);
  }

  editDiseases(imei, data): Observable<any> {
    return this.apiService.patch(`/editDiseases/${imei}`, data);
  }

  //CPL
  getNewCPL(id): Observable<any> {
    return this.apiService.get(`/alarm/meas/${id}`);
  }

  //Carehome
  getHours(imei): Observable<any> {
    return this.apiService.get(`/getHours/${imei}`);
  }


  addExtraNoteToAlarm(data): Observable<any> {
    return this.apiService.post('/alarm/addExtraNote', data);
  }

  //documents
  getDocumentFromDocumentation(id): Observable<any> {
    return this.apiService.get(`/getDocumentation/${id}`);
  }


  uploadDocumentToDocumentation(data): Observable<any> {
    return this.apiService.post('/saveDocumentFile', data);
  }


  getDocuments(imei): Observable<any> {
    return this.apiService.get(`/getDocuments/${imei}`);
  }

  getRegulation(): Observable<any> {
    return this.apiService.get(`/getRegulation`);
  }


  //projects

  getProjectsForManager(): Observable<any> {
    return this.apiService.get(`/getProjects`);
  }

  getProject(id): Observable<any> {
    return this.apiService.get(`/getProject/${id}`);
  }

  checkFreeNameOfProject(): Observable<any> {
    return this.apiService.get(`/checkIfNameProjectIsFree`);
  }

  addProject(projectName, interval, sidePanel, purchaser, place, expectedpeople, contractstart, contractend): Observable<any> {
    return this.apiService.post(`/create-project`, { projectName: projectName, interval: interval, activeSidePanel: sidePanel, purchaser: purchaser, place: place, expectedPeople: expectedpeople, contractStart: contractstart, contractEnd: contractend });
  }

  editProject(id, projectName, interval, activeSidePanel, purchaser, place, expectedpeople, contractstart, contractend): Observable<any> {
    return this.apiService.post(`/edit-project/${id}`, { projectName: projectName, interval: interval, activeSidePanel: activeSidePanel, purchaser: purchaser, place: place, expectedPeople: expectedpeople, contractStart: contractstart, contractEnd: contractend });
  }

  deleteProject(id): Observable<any> {
    return this.apiService.delete(`/delete-project/${id}`);
  }

  getUsersWithDeactivePanel(id): Observable<any> {
    return this.apiService.get(`/manager/users-deactivated-panel/${id}`);
  }

  setActiveSidePanelForUser(id): Observable<any> {
    return this.apiService.get(`/manager/set-active-side-panel/${id}`);
  }

  checkIfManagerHasProject(): Observable<any> {
    return this.apiService.get(`/checkIfManagerHasProject`);
  }


  //sidenav

  getSideNav(): Observable<any> {
    return this.apiService.get(`/getSidenavNotifs`);
  }

  startHandleSideNotif(id): Observable<any> {
    return this.apiService.get(`/startHandle/sideNotif/${id}`);
  }

  handleSideNotif(id, note, turnOffSideNotifs): Observable<any> {
    return this.apiService.get(`/handle/sideNotif/${id}?note=${note}&turnOffSideNotifs=${turnOffSideNotifs}`);
  }

  getCityFromZipCode(zipCode): Observable<any> {
    return this.apiService.get(`/patient/zip-code/${zipCode}`);
  }

  //indivClient

  changeStatus(status): Observable<any> {
    return this.apiService.get(`/patient/changeStatusOfUser/${status}`);
  }

  getBandCard(imei): Observable<any> {
    return this.apiService.get(`/getBandCard/${imei}`);
  }

  getAvatar(userId): Observable<any> {
    return this.apiService.get(`/manager/getAvatar/${userId}`);
  }

  getCallData(imei, pesel): Observable<any> {
    return this.apiService.get(`/manager/getCallData/${imei}/${pesel}`);
  }

  getSpecialClient(imei): Observable<any> {
    return this.apiService.get(`/manager/getSpecialClient/${imei}`);
  }

  setUserDescription(imei, description): Observable<any> {
    return this.apiService.patch(`/manager/setUserDescription/${imei}`, { description: description });
  }

  getBandNote(imei): Observable<any> {
    return this.apiService.get(`/manager/getBandNote/${imei}`);
  }

  setBandNote(imei, note): Observable<any> {
    const timenow = moment().format('YYYY-MM-DD HH:mm:ss');
    return this.apiService.post(`/manager/setBandNote/${imei}`, { note: note, date: timenow });
  }

  getAlarmLocation(alarmId): Observable<any> {
    return this.apiService.get(`/manager/getAlarmLocation/${alarmId}`);
  }

  getLastLocalizations(imei, number): Observable<any> {
    return this.apiService.get(`/manager/getLastLocations/${number}/${imei}`);
  }

  getLastNotifs(imei): Observable<any> {
    return this.apiService.get(`/patient/getLastNotifs/${imei}`);
  }

  getTempMac(imei): Observable<any> {
    return this.apiService.get(`/manager/getTempMac/${imei}`);
  }

  setTempMac(imei, data): Observable<any> {
    return this.apiService.post(`/manager/setTempMac/${imei}`, data);
  }

  deleteTempMac(imei): Observable<any> {
    return this.apiService.delete(`/manager/deleteTempMac/${imei}`);
  }

  getActivities(lang): Observable<any> {
    return this.apiService.get(`/patient/getPlannerActivities/${lang}`);
  }

  setTypeOfGroupResponder(userId, checked): Observable<any> {
    return this.apiService.post(`/patient/setType/responder`, { userId: userId, checked: checked });
  }

  getBandTypeByImei(imei): Observable<any> {
    return this.apiService.get(`/band/getBandTypeByImei/${imei}`,)
  }

  sendLocationSMS(imei): Observable<any> {
    return this.apiService.post(`/send-location-sms/${imei}`, {imei: imei});
  }


  sendCustomSMS(imei, type): Observable<any> {
    return this.apiService.post(`/send-custom-sms/${imei}`, {imei: imei, type: type});
  }

}
