// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//     production: false,
//   avatar_url: 'http://127.0.0.1:8000',
//   api_url: 'http://127.0.0.1:8000/api',
//   socket_url: 'http://127.0.0.1:3130'
// };

export const environment = {
    production: false,
    avatar_url: 'https://api.sidly-platform-dev.com',
    api_url: 'https://api.sidly-platform-dev.com/api',
    socket_url: 'https://api.sidly-platform-dev.com'
};
