import { Component, Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DateService {

    constructor(private translate: TranslateService) { }

    getWeekdayShortName(weekday: number): string {
        return this.translate.instant('weekdays.' + [weekday - 1]);
    }

    getMonthShortName(month: number): string {
        return this.translate.instant('months.' + [month - 1]);
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    fromModel(date: string): NgbDateStruct {
        if (date) {
            const model = new Date(date);
            return model ? { year: model.getFullYear(), month: model.getMonth() + 1, day: model.getDate() } : null;
        } else {
            return null;
        }
    }

  toModel(date: NgbDateStruct): String {
    const month = date.month.toString().length === 1 ? `0${date.month}` : date.month.toString();
    const day = date.day.toString().length === 1 ? `0${date.day}` : date.day.toString();
    return `${date.year}-${month}-${day}`;
  }

}
