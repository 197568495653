import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalService } from '../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-file-document',
  templateUrl: './upload-file-document.component.html',
  styleUrls: ['./upload-file-document.component.scss']
})
export class UploadFileDocumentComponent implements OnInit {

  @Output() changeDocument = new EventEmitter();
  @Input() imei;
  @ViewChild('fileInput') fileInput;
  public document;
  public url;

  constructor(
      private translate: TranslateService,
      private modalService: ModalService
  ) {}

  ngOnInit() {}

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
        if (file.size < 5000000) {

          reader.readAsDataURL(file);
          reader.onload = () => {
            if (typeof reader.result === 'string') {
              this.document = {
                filename: file.name,
                filetype: file.type,
                size: file.size,
                imei: this.imei,
                value: reader.result.split(',')[1]
              };

              this.changeDocument.emit(this.document);
            } else {
              this.modalService.open(this.translate.instant('DOCUMENTS.INVALID_FILE_FORMAT'));
              this.document = null;
              this.fileInput.nativeElement.value = null;
            }
          };
        } else {
          this.modalService.open(this.translate.instant('DOCUMENTS.FILE_TOO_BIG'));
          this.document = null;
          this.fileInput.nativeElement.value = null;
        }
    }
  }
}
