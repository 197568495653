import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { observable } from 'rxjs/symbol/observable';
const io = require('socket.io-client/dist/socket.io.js');

@Injectable()
export class SocketService {

    private socketUrl = environment.socket_url;


    private socket;
    private turnOffAlarm = true;

    public init(userId) {
        if (this.socketUrl === 'http://127.0.0.1:3130') {
            this.socket = io(this.socketUrl, { path: '/socket.io/', query: 'userId=' + userId + '&' });
        } else {
            this.socket = io(this.socketUrl, { path: '/socket2/socket.io/?userId=' + userId + '&' });
        }
    }

    public onAlert(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('sosAlert', data => observer.next(JSON.parse(data)));
        });
    }

    public onAlertEBS(): Observable<any> {
        if (this.turnOffAlarm) {
            return new Observable<any>(observer => {
                this.socket.on('sensorAlarm', data => observer.next(JSON.parse(data)));
            });
        }
    }

    public onAlertCPL(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('cplinfo', data => observer.next(JSON.parse(data)));
        });
    }

    public startCare(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('startCare', data => observer.next(JSON.parse(data)));
        });
    }

    public stopCare(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('stopCare', data => observer.next(JSON.parse(data)));
        });
    }

    public startUsing(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('startUsing', data => observer.next(JSON.parse(data)));
        });
    }

    public updateOfData(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('updateOfData', data => observer.next(JSON.parse(data)));
        });
    }

    public stopUsing(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('stopUsing', data => observer.next(JSON.parse(data)));
        });
    }

    public startCharging(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('startCharge', data => observer.next(JSON.parse(data)));
        });
    }

    public onNotification(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('informationNotification', data => observer.next(JSON.parse(data)));
        });
    }

    public acceptRequest(data) {
        this.socket.emit('canceling', data);
    }

    public checkAlarms() {
        this.socket.emit('checkAlarm');
    }


    public onAccept(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('canceling', data => observer.next(JSON.parse(data)));
        });
    }

    public onStatus(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('statusOfCaregivers', data => observer.next(JSON.parse(data)));
        });
    }

    // public onUserInfo(): Observable<any> {
    //     return new Observable<any>(observer => {
    //         this.socket.on('bandsLiveData', data => observer.next(JSON.parse(data)));
    //     });
    // }

    public handleSideNotif(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('handleSideNotif', data => observer.next(JSON.parse(data)));
        });
    }

    public startHandleSideNotif(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('startHandleSideNotif', data => observer.next(JSON.parse(data)));
        });
    }

    public refreshOnAlarmHandle(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('refresh-page', data => observer.next(JSON.parse(data)));
        });
    }

    public finishedChangeImei(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('finishedChangeImei', data => observer.next(JSON.parse(data)));
        });
    }

    public snackbarAH(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('snackbarAH', data => observer.next(JSON.parse(data)));
        });
    }

    public snackbar(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('snackbar', data => observer.next(JSON.parse(data)));
        });
    }

    public sendSignal(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('sendSignal', data => observer.next(JSON.parse(data)));
        });
    }

    public close() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

}
