import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input('data') data;
  @Input('itemsPerPage') itemsPerPageValue = 10;
  @Output() outputData: EventEmitter<Object> = new EventEmitter<Object>();
  public itemsPerPage: number;
  public totalItems: any;
  public page: any;
  public previousPage: any;
  private paginatedData: any;
  public innerWidth: any;

  constructor() { }

  ngOnInit() {
    this.itemsPerPage = this.itemsPerPageValue;
    this.totalItems = this.data.length;
    this.page = 1;
    this.innerWidth = window.innerWidth;
  }

  public loadPage(page: number): void {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.loadData();
    }
  }

  private loadData(): void {
    this.paginatedData = this.data.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);
    this.outputData.emit(this.paginatedData);
  }

}
