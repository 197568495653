import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

import { distinctUntilChanged } from 'rxjs/operators';

import { UserService } from './services/user.service';

@Directive({ selector: '[showAuthed]' })
export class ShowAuthedDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainer: ViewContainerRef
  ) { }

  role: string;

  ngOnInit() {
    this.userService.isAuthenticated
    .pipe(distinctUntilChanged())
    .subscribe(
      (isAuthenticated) => {
        if (isAuthenticated &&
          this.userService.getRole() >= this.userService.getIntRole(this.role) ||
          !isAuthenticated && this.role === 'none'
        ) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      }
    );
  }

  @Input() set showAuthed(role: string) {
    this.role = role;
  }

}
