import { Component, OnInit, ViewChild, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../services';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-change-avatar',
  templateUrl: './change-avatar.component.html',
  styleUrls: ['./change-avatar.component.scss']
})
export class ChangeAvatarComponent implements OnInit {

  @Input() edit: boolean;
  @Input() linkAvatar;
  @Output() changeAvatar = new EventEmitter();
  @ViewChild('fileInput') fileInput;
  public avatar;
  public url;

  constructor(
      private translate: TranslateService,
      private modalService: ModalService,
      private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.url = environment.avatar_url;
    this.getAvatar();
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
        let fileSize = +(((file.size / 1024) / 1024)).toFixed(2);
        if (fileSize < 5) {

          reader.readAsDataURL(file);
          reader.onload = () => {
            if (typeof reader.result === 'string') {
              this.avatar = {
                filename: file.name,
                filetype: file.type,
                value: reader.result.split(',')[1]
              };

              this.changeAvatar.emit(this.avatar);
            } else {
              this.modalService.open(this.translate.instant('BACKEND.INVALID_FILE_FORMAT'));
              this.avatar = null;
              this.linkAvatar = null;
              this.fileInput.nativeElement.value = null;
              this.sendAvatar();
            }
          };
        } else {
          this.modalService.open(this.translate.instant('BACKEND.AVATAR_TOO_BIG'));
          this.avatar = null;
          this.linkAvatar = null;
          this.fileInput.nativeElement.value = null;
          this.sendAvatar();
        }
      }
    }
  }

  // sprawdzamy czy nie mamy juz jakiegos avataru i wtedy do niego wracamy
  private sendAvatar(): void {
    if (this.linkAvatar != null && !this.avatar) {
      this.changeAvatar.emit(this.linkAvatar);
    } else {
      this.changeAvatar.emit(this.avatar);
    }
  }

  deleteAvatar() {
    this.ngZone.run(() => {
      this.avatar = null;
      this.fileInput.nativeElement.value = null;
      this.linkAvatar = null;
      this.changeAvatar.emit(this.avatar);
    });
  }

  public getAvatar(): string {
    if (typeof this.linkAvatar === 'string' && !this.linkAvatar.includes('base64')) {
      return this.url + this.linkAvatar;
    } else {
      if (this.linkAvatar != undefined) {
        return 'data:' + this.linkAvatar.filetype + ';base64,' + this.linkAvatar.value;
      }
    }
    return ''; // Added return statement for cases where no avatar is set
  }

}
