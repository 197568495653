import { Component, Injectable, OnInit, OnDestroy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs';
import { FormsModule } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

@Component({
  selector: 'app-phone-picker',
  templateUrl: './phone-picker.component.html',
  styleUrls: ['./phone-picker.component.scss']
})

export class PhonePickerComponent implements OnInit, OnDestroy {

  @Input() prefixFormControl: FormControl;
  @Input() phoneFormControl: FormControl;
  @Input() height: number;

  public langs = [
    { lang: 'en', prefix: '+44' },
    { lang: 'pl', prefix: '+48' },
    { lang: 'de', prefix: '+49' },
    { lang: 'cs', prefix: '+420' },
    { lang: 'fr', prefix: '+33' },
    { lang: 'pt', prefix: '+351' },
    { lang: 'it', prefix: '+39' },
    { lang: 'ro', prefix: '+40' },
    { lang: 'da', prefix: '+45' },
    { lang: 'ar', prefix: '+966' },
    { lang: 'bg', prefix: '+359' },
    { lang: 'at', prefix: '+43' },
    { lang: 'be', prefix: '+32' },
    { lang: 'cy', prefix: '+357' },
    { lang: 'es', prefix: '+34' },
    { lang: 'ee', prefix: '+372' },
    { lang: 'fi', prefix: '+358' },
    { lang: 'gr', prefix: '+30' },
    { lang: 'hu', prefix: '+36' },
    { lang: 'ie', prefix: '+353' },
    { lang: 'lu', prefix: '+352' },
    { lang: 'lt', prefix: '+370' },
    { lang: 'lv', prefix: '+371' },
    { lang: 'nl', prefix: '+31' },
    { lang: 'se', prefix: '+46' },
    { lang: 'sk', prefix: '+421' },
    { lang: 'sl', prefix: '+386' },
    { lang: 'is', prefix: '+354' },
    { lang: 'no', prefix: '+47' },
    { lang: 'hr', prefix: '+385' },
    { lang: 'al', prefix: '+355' },
    { lang: 'ch', prefix: '+41' },
    { lang: 'usa', prefix: '+1' },
    { lang: 'ua', prefix: '+380' },
    { lang: 'sr', prefix: '+381' },
    { lang: 'mt', prefix: '+356' },
  ];
  public current;
  public searchTerm$ = new Subject<string>();
  public mask = "000000000000000";
  constructor(private translateService: TranslateService) { }

  ngOnInit() {

    this.searchTerm$
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(searchKey => {
        this.onKeyChangeInput(searchKey);
      });

    if (!this.prefixFormControl.value) {
      this.prefixFormControl.setValue(this.translateService.currentLang);
    }

    const index = this.langs.findIndex(indx => indx.lang === this.prefixFormControl.value || indx.prefix === this.prefixFormControl.value);

    if (index !== -1) {
      this.changeAreaCode(this.langs[index]);
    } else {
      this.changeAreaCode({ lang: 'question', prefix: this.prefixFormControl.value });
    }

  }

  public changeAreaCode(lang): void {
    this.current = lang;
    this.prefixFormControl.setValue(this.current.prefix);
  }

  private onKeyChangeInput(searchValue): void {
    const index = this.langs.map(e => e.prefix).indexOf(searchValue);

    if (index !== -1) {
      this.changeAreaCode(this.langs[index]);
    } else {
      this.changeAreaCode({ lang: 'question', prefix: searchValue });
    }
  }

  ngOnDestroy() {
    if (this.searchTerm$) {
      this.searchTerm$.unsubscribe();
    }
  }

}
