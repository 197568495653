import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class StarsComponent {
  @Input() rating: number;

  fullStars: number;
  halfStars: boolean;
  emptyStars: number;

  ngOnChanges(): void {
    this.fullStars = Math.floor(this.rating);
    this.halfStars = (this.rating % 1) !== 0;
    this.emptyStars = 5 - this.fullStars - (this.halfStars ? 1 : 0);
  }

  fullStarsRange(): number[] {
    return Array(this.fullStars).fill(0).map((_, i) => i);
  }

  hasHalfStar(): boolean {
    return this.halfStars;
  }

  emptyStarsRange(): number[] {
    return Array(this.emptyStars).fill(0).map((_, i) => i);
  }
}
