import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {PatientService} from '../../services/patient.service';
import {UserService} from '../../services/user.service';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-show-ebsalert-info',
  templateUrl: './show-ebsalert-info.component.html',
  styleUrls: ['./show-ebsalert-info.component.scss']
})
export class ShowEbsalertInfoComponent implements OnInit {

    @Input() data;
    @Input() bandType;
    @Input() firstName;
    @Input() lastName;
    @Input() avatar;
    public handledTime: string;
    public addAlarmForm: FormGroup;
    public showNote = false;
    public userMe;

    constructor(public activeModal: NgbActiveModal,
                private patientService: PatientService,
                private location: Location,
                private fb: FormBuilder,
                private userService: UserService,) {

        this.addAlarmForm = this.fb.group({
            'descId': '',
            'extraNote': ['', [Validators.required, Validators.minLength(5)]],
        });
    }

    ngOnInit() {
        if(this.data)

        this.userMe = this.userService.getCurrentUser().user_id;
    }


    close() {
        this.activeModal.close();
    }

    addExtraNote(){

        if(this.addAlarmForm.valid) {
            var note = <HTMLInputElement>document.getElementById('extraNote');

            var today = new Date().toLocaleString();
            note.value = today + ' - ' + this.userMe['first_name'] + ' ' + this.userMe['last_name'] + ' \n' + note.value;

            this.addAlarmForm.patchValue({
                descId: this.data.descId,
                extraNote: note.value,
            });
            const credentials = this.addAlarmForm.value;

            this.patientService.addExtraNoteToAlarm(credentials).subscribe(
                extra => {
                    this.showNote = !this.showNote;

                    if(this.data.extraNote) {
                        this.data.extraNote = this.data.extraNote + '\n\n' + note.value;
                    } else{
                        this.data.extraNote = note.value;
                    }

                    note.value = ' ';

                    this.addAlarmForm.patchValue({
                        descId: this.data.descId,
                        extraNote: '',
                    });
                }
            );
        }
    }

}
